import DynamicButton from '../components/common/DynamicButton';
import { useNavigate } from 'react-router-dom';
import { Topic } from '../utils/types';
import { useUserContext } from '../components/Providers/UserContext';
import Loader from '../components/common/Loader';
import TopicsTable from '../components/Topic/TopicsTable';

interface TopicProps {
  topicList: Topic[];
  isLoading: boolean;
}

const Topics = ({ topicList, isLoading }: TopicProps) => {
  const { hasRole } = useUserContext();
  const navigate = useNavigate();

  return (
    <section className="tutor-page-container">
      <div className="tutor-page-content">
        {hasRole('Admin') && (
          <div
            className="add-tutor-btn flex space-between align-center full"
            style={{
              width: '100%',
            }}
          >
            <p className="h5-headline">Topics</p>
            <DynamicButton
              size="medium"
              type="primary"
              addIcon={true}
              onClick={() => navigate('/topicadd')}
            >
              New topic
            </DynamicButton>
          </div>
        )}

        {/* {topicList && topicList.map((topic) => <TopicCard topic={topic} key={topic.id} />)} */}
        {/* {!topicList && isLoading && <Loader />} */}
        {topicList && topicList.length > 0 && isLoading ? (
          <Loader />
        ) : (
          <div className="tutor-list">
            <TopicsTable topicList={topicList} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Topics;
