import { useState } from 'react';
import BackButton from '../components/layout/BackButton';
import CsvUploader from '../components/common/CsvUploader';
import AddMultiplePublicSessions from '../components/Session/AddMultiplePublicSessions';
import { TemplateSession } from '../utils/types';

const PublicMultipleSessions = () => {
  const [isShowPreviewScreen, setIsShowPreviewScreen] = useState<boolean>(false);
  const [uploadedSessions, setUploadedSessions] = useState<TemplateSession[] | null>(null);

  const handleBackBtn = () => {
    setIsShowPreviewScreen(false);
    setUploadedSessions(null);
  };

  return (
    <section className="public-multiple-sessions-section">
      <BackButton onClick={handleBackBtn} navigatePath={`/publicsessions`} />
      <div className="content">
        {!isShowPreviewScreen && (
          <CsvUploader
            setIsShowPreviewScreen={setIsShowPreviewScreen}
            setUploadedSessions={setUploadedSessions}
          />
        )}
        {isShowPreviewScreen && uploadedSessions && (
          <AddMultiplePublicSessions uploadedSessions={uploadedSessions} />
        )}
      </div>
    </section>
  );
};

export default PublicMultipleSessions;
