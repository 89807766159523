import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import dayjs from 'dayjs';
import { useQueryClient } from '@tanstack/react-query';
import Dialog from '@mui/material/Dialog';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import CalendarMonthOutlinedIcon from '@mui/icons-material/CalendarMonthOutlined';
import { Session, TimeZone } from '../../utils/types';
import { TimezonePicker } from '../form/TimezonePicker';
import DynamicButton from '../common/DynamicButton';
import { TemplateSession, ParsedSession, TimeFramePublic } from '../../utils/types';
import MultiplePublicSessionsPreview from './MultiplePublicSessionsPreview';
import { PublicSessionsResponseSummary } from './PublicSessionsResponseSummary';
import { createMultipleSessionsPublic } from '../../utils/Network';
import { useUserContext } from '../../components/Providers/UserContext';
import { convertToCSVFormat, getOffset } from '../../utils/utilsts';

interface AddMultiplePublicSessionsProps {
  uploadedSessions: TemplateSession[] | null;
}

const AddMultiplePublicSessions = ({ uploadedSessions }: AddMultiplePublicSessionsProps) => {
  const { userToken } = useUserContext();
  const navigate = useNavigate();
  const [selectedTimezone, setSelectedTimezone] = useState<TimeZone>({
    label: 'US/Eastern',
    offset: '-05:00',
  });
  const [responseSessions, setResponseSessions] = useState<Session[]>([]);
  const [isSummaryModalOpen, setIsSummaryModalOpen] = useState<boolean>(false);
  const [isPublishBtnDisable, setIsPublishBtnDisable] = useState<boolean>(true);
  const [scheduleTemplate, setScheduleTemplate] = useState<ParsedSession[]>([]);

  useEffect(() => {
    if (responseSessions && responseSessions.length > 0) {
      setIsSummaryModalOpen(true);
    }
  }, [responseSessions]);

  const [timeFrame, setTimeFrame] = useState<TimeFramePublic>({
    startDate: null,
    endDate: null,
    offset: -5,
  });

  useEffect(() => {
    if (
      typeof timeFrame.startDate === 'string' &&
      typeof timeFrame.endDate === 'string' &&
      dayjs(timeFrame.startDate).isBefore(timeFrame.endDate, 'day')
    ) {
      setIsPublishBtnDisable(false);
    } else {
      setIsPublishBtnDisable(true);
    }
  }, [timeFrame.startDate, timeFrame.endDate]);

  useEffect(() => {
    if (uploadedSessions) {
      const sessionForSceduleTemplate = convertToCSVFormat(uploadedSessions);
      setScheduleTemplate(sessionForSceduleTemplate);
    }
  }, [uploadedSessions]);

  const handleTimeZoneChange = (val: TimeZone) => {
    setTimeFrame((prevData) => ({
      ...prevData,
      offset: getOffset(val.offset),
    }));
    setSelectedTimezone(val);
  };

  const handleStartDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      setTimeFrame((prevData) => ({
        ...prevData,
        startDate: date.format('YYYY-MM-DD'),
      }));
    }
  };

  const handleEndDateChange = (date: dayjs.Dayjs | null) => {
    if (date) {
      if (timeFrame.startDate && date.isAfter(timeFrame.startDate)) {
        setTimeFrame((prevData) => ({
          ...prevData,
          endDate: date.format('YYYY-MM-DD'),
        }));
      }
    }
  };

  const onSubmit = async () => {
    try {
      if (typeof timeFrame.startDate === 'string' && typeof timeFrame.endDate === 'string') {
        const generatedPublicSessions = await createMultipleSessionsPublic(
          timeFrame,
          scheduleTemplate,
          userToken,
        );
        if (generatedPublicSessions) {
          setResponseSessions(generatedPublicSessions);
        }
      }
    } catch (e) {
      console.log('Failed to generate sessions:', e);
    }
  };

  // const checkDisableStartDate = (date: dayjs.Dayjs): boolean => {
  //   return !date.isBefore(timeFrame.endDate, 'day');
  // };

  const checkDisableEndDate = useCallback(
    (date: dayjs.Dayjs): boolean => {
      return timeFrame.startDate ? !date.isAfter(timeFrame.startDate, 'day') : false;
    },
    [timeFrame.startDate],
  );

  const queryClient = useQueryClient();

  const updateAllSessions = () => {
    queryClient.invalidateQueries({
      queryKey: ['sessions'],
      exact: false, // Ensure all keys starting with 'sessions' are invalidated
    });
  };

  const handleCloseSummary = () => {
    setIsSummaryModalOpen(false);
    updateAllSessions();
    navigate('/');
  };

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <section className="add-multiple-public-sessions-section flex column full gap-48">
        <div className="settings flex gap-24 space-between align-center">
          <div className="timezone-dates flex gap-24">
            <div style={{ width: '368px' }}>
              <TimezonePicker
                changeTimeZone={handleTimeZoneChange}
                smallLabelMargin
                value={selectedTimezone}
              />{' '}
            </div>
            <div className="datepicker">
              <p className="form-label margin-8">Schedule start</p>
              <DatePicker
                value={null}
                disablePast
                onChange={(newVal) => handleStartDateChange(newVal)}
                format={'DD/MM/YYYY'}
                slots={{
                  openPickerIcon: CalendarMonthOutlinedIcon,
                }}
                slotProps={{
                  openPickerIcon: {
                    style: {
                      color: 'black',
                    },
                  },
                  textField: {
                    placeholder: 'Add date',
                  },
                  layout: {
                    sx: {
                      borderRadius: '8px',
                    },
                  },
                  day: {
                    sx: {
                      fontFamily: 'OpenSans',
                      '&:hover': {
                        fontWeight: '600',
                        color: '#9E007E',
                        border: '2px solid',
                        borderColor: '#9E007E',
                        backgroundColor: 'rgba(158,0, 26,0.05)',
                      },
                      '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#9E007E !important',
                        '&:hover': {
                          backgroundColor: '#722351',
                          border: 'none',
                          fontWeight: '500',
                        },
                      },
                      '&:disabled': {
                        color: 'rgba(145, 145, 145, 1)',
                      },
                      '&.MuiPickersDay-today': {
                        border: 'none',
                        backgroundColor: '#E6E6E6',
                        '&:hover': {
                          fontWeight: '600',
                          color: '#9E007E',
                          border: '2px solid',
                          borderColor: '#9E007E',
                          backgroundColor: 'rgba(158,0, 26,0.05)',
                        },
                      },
                    },
                  },
                }}
              />
            </div>
            <div className="datepicker">
              <p className="form-label margin-8">Schedule end</p>
              <DatePicker
                value={null}
                disablePast
                shouldDisableDate={checkDisableEndDate}
                onChange={(newVal) => handleEndDateChange(newVal)}
                format={'DD/MM/YYYY'}
                slots={{
                  openPickerIcon: CalendarMonthOutlinedIcon,
                }}
                slotProps={{
                  openPickerIcon: {
                    style: {
                      color: 'black',
                    },
                  },
                  textField: {
                    placeholder: 'Add date',
                  },
                  layout: {
                    sx: {
                      borderRadius: '8px',
                    },
                  },
                  day: {
                    sx: {
                      fontFamily: 'OpenSans',
                      '&:hover': {
                        fontWeight: '600',
                        color: '#9E007E',
                        border: '2px solid',
                        borderColor: '#9E007E',
                        backgroundColor: 'rgba(158,0, 26,0.05)',
                      },
                      '&.Mui-selected': {
                        color: 'white',
                        backgroundColor: '#9E007E !important',
                        '&:hover': {
                          backgroundColor: '#722351',
                          border: 'none',
                          fontWeight: '500',
                        },
                      },
                      '&:disabled': {
                        color: 'rgba(145, 145, 145, 1)',
                      },
                      '&.MuiPickersDay-today': {
                        border: 'none',
                        backgroundColor: '#E6E6E6',
                        '&:hover': {
                          fontWeight: '600',
                          color: '#9E007E',
                          border: '2px solid',
                          borderColor: '#9E007E',
                          backgroundColor: 'rgba(158,0, 26,0.05)',
                        },
                      },
                    },
                  },
                }}
              />
            </div>
          </div>
          <DynamicButton
            type="primary"
            size="large"
            onClick={() => onSubmit()}
            disable={isPublishBtnDisable}
          >
            Publish
          </DynamicButton>
        </div>
        <div className="schedule">
          {uploadedSessions && (
            <MultiplePublicSessionsPreview uploadedSessions={uploadedSessions} />
          )}
        </div>
        <Dialog open={isSummaryModalOpen} onClose={() => handleCloseSummary()} maxWidth={'xl'}>
          <PublicSessionsResponseSummary
            sessions={responseSessions}
            timezone={selectedTimezone}
            handleCloseSummary={handleCloseSummary}
          />
        </Dialog>
      </section>
    </LocalizationProvider>
  );
};

export default AddMultiplePublicSessions;
