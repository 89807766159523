import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import FileDownloadOutlinedIcon from '@mui/icons-material/FileDownloadOutlined';
import { Session, TimeZone } from '../../utils/types';
import dayjs from 'dayjs';
import DynamicButton from '../common/DynamicButton';
import isoWeek from 'dayjs/plugin/isoWeek';

interface PublicSessionsResponseSummaryProps {
  sessions: Session[];
  timezone: TimeZone;
  handleCloseSummary: () => void;
}

export const PublicSessionsResponseSummary = ({
  sessions = [],
  timezone,
  handleCloseSummary,
}: PublicSessionsResponseSummaryProps) => {
  dayjs.extend(isoWeek);

  const getSessionTimeFormatted = (session: Session) => {
    try {
      if (session.date) {
        return dayjs(new Date(session.date)).utcOffset(timezone.offset).format('HH:mm');
      }
    } catch (error) {
      if (session.date) {
        return `${dayjs(new Date(session.date)).tz('EST').format('HH:mm')} EST time`;
      }
    }
  };

  const downloadTextAsFile = (blob: Blob, filename: string) => {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;

    // Append the anchor element to the body
    document.body.appendChild(a);

    // Programmatically trigger a click event on the anchor element
    a.click();

    // Remove the temporary anchor element from the document
    document.body.removeChild(a);
  };

  const columns: GridColDef<(typeof rows)[number]>[] = [
    { field: 'id', headerName: 'Session Id', width: 90, type: 'number' },
    {
      field: 'date',
      headerName: 'Date',
      width: 100,
    },
    {
      field: 'weekday',
      headerName: 'Weekday',
      width: 100,
    },
    {
      field: 'time',
      headerName: 'Time',
      width: 100,
    },
    {
      field: 'category',
      headerName: 'Categoty',
      width: 150,
    },
    {
      field: 'GSELevel',
      headerName: 'GSE Level',
      width: 150,
    },
    {
      field: 'topic',
      headerName: 'Topic',
      width: 200,
    },
    {
      field: 'contentSensitivity',
      headerName: 'Content sensitivity',
      width: 100,
    },
    {
      field: 'vendor',
      headerName: 'Vendor',
      width: 100,
    },
  ];

  const getDate = (session: Session) => {
    if (session.date) {
      // return dayjs(new Date(session.date)).tz(timezone.label).format('YYYY-MM-DD');
      return dayjs(new Date(session.date))
        .utcOffset(timezone.offset.replace('UTC', ''))
        .format('YYYY-MM-DD');
    }
  };

  const getWeekday = (session: Session) => {
    if (session.date) {
      // return dayjs(new Date(session.date)).tz(timezone.label).format('ddd');
      return dayjs(new Date(session.date))
        .utcOffset(timezone.offset.replace('UTC', ''))
        .format('ddd');
    }
  };

  const rows = sessions
    .filter((session) => session.date) // Exclude undefined dates
    .sort((a, b) => new Date(a.date!).getTime() - new Date(b.date!).getTime())
    .map((session) => ({
      id: session.id.toString().replace(/,/g, ''),
      date: getDate(session),
      weekday: getWeekday(session),
      time: getSessionTimeFormatted(session),
      category: session.category.category,
      GSELevel: session.GSELevel?.level,
      topic: session.topic?.title ? JSON.stringify(session.topic.title, null, 2) : 'Placeholder',
      contentSensitivity: session.topic?.contentSensitivity.toString(),
      vendor: session.vendor,
    }));

  type DataItem = {
    id: string;
    date: string;
    weekday: string;
    time?: string;
    category: string;
    GSELevel?: string;
    topic: string;
    contentSensitivity?: string;
    vendor: string;
  };

  const getSessionsResponseCsv = () => {
    // const rowsString = JSON.stringify(rows);
    // const blob = new Blob([rowsString], { type: 'application/json' });
    // Step 1: Extract the headers
    const headers = Object.keys(rows[0]) as Array<keyof DataItem>;

    // Step 2: Create the header row
    const headerRow = headers.join(',');

    // Step 3: Convert each object to a CSV row
    const csvRows: string[] = rows.map((item) => {
      return headers.map((header) => item[header] ?? '').join(',');
    });

    // Step 4: Combine the header row and the data rows
    const csvString = [headerRow, ...csvRows].join('\n');

    // Step 5: Create a Blob from the CSV string
    const blob = new Blob([csvString], { type: 'text/csv' });
    downloadTextAsFile(blob, `multiple-sessions-response-${new Date().toLocaleString()}.csv`);
  };

  type LevelCount = {
    [key: string]: number;
  };

  const GSELevelsCount = sessions.reduce(
    (accumulator: LevelCount, session: Session): LevelCount => {
      const levelName = session.GSELevel?.level;
      if (levelName) {
        if (accumulator[levelName]) {
          accumulator[levelName]++;
        } else {
          accumulator[levelName] = 1;
        }
      }

      return accumulator;
    },
    {} as LevelCount,
  );

  return (
    <section className={`sessions-response-summary-section`} style={{ padding: '20px' }}>
      <div className="sessions-response-summary-content">
        <div className="statistics flex gap-24" style={{ paddingBottom: '5px' }}>
          <div className="response-info full">
            <h3>Multiple sessions created info</h3>
            <div className="details-item flex">
              <div className="info-title">Timezone:</div>
              <div className="detail-content flex gap-8">
                {<p className="details">{JSON.stringify(timezone.label, null, 2)}</p>}
              </div>
            </div>
            <div className="details-item flex">
              <div className="info-title">Start date</div>
              <div className="detail-content flex gap-8">
                {sessions && sessions.length > 0 && (
                  <p className="details">{`${dayjs(sessions[0].date)
                    .utcOffset(timezone.offset.replace('UTC', ''))
                    .format('YYYY-MM-DD')}`}</p>
                )}
              </div>
            </div>
            <div className="details-item flex">
              <div className="info-title">Last session date</div>
              <div className="detail-content flex gap-8">
                {sessions && sessions.length > 0 && (
                  <p className="details">
                    {`${dayjs(sessions[sessions.length - 1].date)
                      .utcOffset(timezone.offset.replace('UTC', ''))
                      .format('YYYY-MM-DD')}`}
                  </p>
                )}
              </div>
            </div>

            <DynamicButton
              size="small"
              type="primary"
              longPadding
              onClick={() => getSessionsResponseCsv()}
            >
              <FileDownloadOutlinedIcon />
              Download as .csv
            </DynamicButton>
          </div>
          <div className="response-info full">
            <div className="details-item flex">
              <div className="info-title">GSE Levels created:</div>
              <div className="detail-content flex gap-8">
                {sessions && (
                  <p style={{ whiteSpace: 'pre' }}>
                    {Object.keys(GSELevelsCount)
                      .map((key) => `${key}: ${GSELevelsCount[key]}`)
                      .join('\r\n')}
                  </p>
                )}
              </div>
            </div>
            <div className="details-item flex">
              <div className="info-title">Total # of sessions created</div>
              <div className="detail-content flex gap-8">
                {sessions && <p className="details">{sessions.length}</p>}
              </div>
            </div>
          </div>
        </div>
        <div className="data-grid-container">
          <Box sx={{ height: 500, width: '100%' }}>
            <DataGrid rows={rows} columns={columns} disableRowSelectionOnClick />
          </Box>
        </div>
      </div>
      <div className="flex justify-end" style={{ paddingTop: '5px' }}>
        <DynamicButton size="small" longPadding type="primary" onClick={() => handleCloseSummary()}>
          View public group schedule
        </DynamicButton>
      </div>
    </section>
  );
};
