import React from 'react';

const GradientCircularLoader = () => {
  return (
    <div style={styles.loaderContainer}>
      <svg
        width="18"
        height="18"
        viewBox="0 0 40 40"
        xmlns="http://www.w3.org/2000/svg"
        style={styles.svg}
      >
        <defs>
          <linearGradient id="gradient" x1="1" y1="0" x2="0" y2="1" gradientUnits="userSpaceOnUse">
            <stop offset="5%" stopColor="#722351" />
            {/* <stop offset="73%" stopColor="#9E007E" /> */}
            <stop offset="100%" stopColor="#722351" />
          </linearGradient>
        </defs>

        {/* Underlying light grey circle */}
        <circle
          cx="20"
          cy="20"
          r="16"
          fill="none"
          stroke="rgba(232, 238, 250, 1)"
          strokeWidth="4"
        />

        {/* Animated gradient circle */}
        <circle
          cx="20"
          cy="20"
          r="16"
          fill="none"
          stroke="url(#gradient)"
          strokeWidth="4"
          strokeDasharray="100"
          strokeDashoffset="50"
          strokeLinecap="butt"
          style={styles.circle}
        />
      </svg>
    </div>
  );
};

const styles = {
  loaderContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  svg: {
    animation: 'rotate 1.5s linear infinite',
  },
  circle: {
    animation: 'dash 1.5s ease-in-out infinite',
  },
};

// Add keyframes for animations
const keyframesStyle = document.createElement('style');
keyframesStyle.innerHTML = `
  @keyframes rotate {
    100% {
      transform: rotate(360deg);
    }
  }

  @keyframes dash {
    0% {
      stroke-dasharray: 1, 100;
      stroke-dashoffset: 0;
    }
    50% {
      stroke-dasharray: 50, 100;
      stroke-dashoffset: -25;
    }
    100% {
      stroke-dasharray: 50, 100;
      stroke-dashoffset: -100;
    }
  }
`;
document.head.appendChild(keyframesStyle);

export default GradientCircularLoader;
