import { useState, useRef } from 'react';
import * as xlsx from 'xlsx';
import DynamicButton from './DynamicButton';
import SmallLoader from './GradientSmallLoader';
import { TemplateSession, ParsedSession } from '../../utils/types';
import { convertParsedDataToTemplateSessions } from '../../utils/utilsts';
import { ReactComponent as SuccessIcon } from '../../assets/image/banner-success-24.svg';
import { ReactComponent as ErrorIcon } from '../../assets/image/banner-error-24.svg';

interface CsvUploaderProps {
  setIsShowPreviewScreen: (val: boolean) => void;
  setUploadedSessions: (val: TemplateSession[]) => void;
}

const CsvUploader = ({ setIsShowPreviewScreen, setUploadedSessions }: CsvUploaderProps) => {
  const fileInputRef = useRef<HTMLInputElement | null>(null);
  const [isUploadCsvClicked, setIsUploadCsvClicked] = useState(false);
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState<{ type: 'success' | 'error'; text: string } | null>(null);
  // const [parsedData, setParsedData] = useState<ParsedSession[]>([]);

  const handleFileChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    setLoading(true);
    setIsUploadCsvClicked(true);
    const file = event.target.files?.[0];
    if (!file) {
      setMessage({ type: 'error', text: 'No file selected!' });
      return;
    }

    if (file.type !== 'text/csv') {
      setMessage({ type: 'error', text: 'Please upload a valid CSV file!' });
      return;
    }

    try {
      // Read the file as an ArrayBuffer
      const fileData = await file.arrayBuffer();

      // Parse the file using XLSX
      const workbook = xlsx.read(fileData, { type: 'array' });
      const sheetName = workbook.SheetNames[0]; // Assuming the first sheet
      const worksheet = workbook.Sheets[sheetName];

      // Convert sheet to JSON
      const data: ParsedSession[] = xlsx.utils.sheet_to_json(worksheet, { defval: '' });
      // setParsedData(data);
      const { sessions, errors } = convertParsedDataToTemplateSessions(data);
      if (errors.length > 0) {
        setMessage({ type: 'error', text: errors[0] });
      } else {
        setUploadedSessions(sessions);
        setMessage({ type: 'success', text: 'File uploaded successfully!' });
      }
    } catch (error) {
      console.error('Error reading file:', error);
      setMessage({ type: 'error', text: 'Failed to upload the file. Please try again!' });
    } finally {
      setLoading(false);
    }
  };

  const handleUploadCSVTemplate = () => {
    if (fileInputRef.current) {
      fileInputRef.current.click();
    }
  };

  const handleContinueBtn = () => {
    setIsShowPreviewScreen(true);
  };

  return (
    <section className="flex column gap-24 justify-center align-center">
      {/* Hidden file input */}
      <input
        type="file"
        accept=".csv"
        ref={fileInputRef}
        style={{ display: 'none' }}
        onChange={handleFileChange}
      />
      {!loading && !message && !isUploadCsvClicked && (
        <DynamicButton
          size="large"
          type="primary"
          fileUploadIcon
          onClick={() => handleUploadCSVTemplate()}
          longPadding
        >
          Upload CSV template
        </DynamicButton>
      )}

      {loading && (
        <div className="flex gap-8 justify-center">
          <SmallLoader />
          <span style={{ fontFamily: 'OpenSans-SemiBold' }}>Uploading...</span>
        </div>
      )}

      {isUploadCsvClicked && message && message.type === 'success' && !loading && (
        <div className="flex gap-8 justify-center">
          <SuccessIcon />
          <span style={{ color: '#008638', fontFamily: 'OpenSans-SemiBold' }}>
            Upload successful
          </span>
        </div>
      )}

      {isUploadCsvClicked && message && message.type === 'error' && !loading && (
        <div className="flex gap-8 justify-center">
          <ErrorIcon />
          <span style={{ color: '#D30018' }}>{message.text}</span>
        </div>
      )}

      {isUploadCsvClicked && (
        <div className="flex gap-8">
          <DynamicButton
            size="large"
            type="tertiary"
            longPadding
            refreshIcon
            onClick={() => handleUploadCSVTemplate()}
          >
            Try again
          </DynamicButton>
          <DynamicButton
            size="large"
            type="primary"
            longPadding
            disable={loading || message?.type !== 'success'}
            onClick={() => handleContinueBtn()}
          >
            Continue
          </DynamicButton>
        </div>
      )}
    </section>
  );
};

export default CsvUploader;
