import { useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { deleteSession } from '../../utils/Network';
import Box from '@mui/material/Box';
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import Avatar from '../common/Avatar';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import PodcastsIcon from '@mui/icons-material/Podcasts';
import DynamicButton from '../common/DynamicButton';
import { Session } from '@utils/types';
import dayjs from 'dayjs';
import { useTutorContext } from '../../providers/TutorContext';
import { useTopicContext } from '../../providers/TopicContext';
import { useUserContext } from '../../components/Providers/UserContext';
import { useTimeZoneContext } from '../../providers/TimeZoneProvider';

interface SessionRowProps {
  session: Session;
}

export default function SessionRow({ session }: SessionRowProps) {
  const [open, setOpen] = useState(false);
  const [linkCopied, setLinkCopied] = useState(false);
  const navigate = useNavigate();
  const { setTutorById } = useTutorContext();
  const { setTopicById } = useTopicContext();
  const { timeZone } = useTimeZoneContext();
  const { userToken } = useUserContext();
  // console.log('timeZone: ', timeZone);

  const formatSeconds = (seconds: number) => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return minutes + 'm ' + remainingSeconds + 's';
  };

  const handleCopyClick = async (link: string | undefined) => {
    try {
      if (link) {
        await navigator.clipboard.writeText(link);
        setLinkCopied(true);
      }
    } catch (error) {
      console.error('Failed to copy link: ', error);
    }
  };

  useMemo(() => {
    setTimeout(() => {
      if (linkCopied) {
        setLinkCopied(false);
      }
    }, 3000);
  }, [linkCopied]);

  const detailsTableStyle = {
    width: 'fit-content',
    '& .MuiTableHead-root': {
      '& .MuiTableCell-root': {
        fontWeight: '600',
      },
    },
  };

  const rowStyle = {
    borderRadius: '4px',
    verticalAlign: 'top',
    '&.live': {
      background: '#005D77 ',
      '& .MuiTableCell-root': {
        color: 'white',
      },
    },
    '&.notLive': {
      background: '#F3F8FB',
      boxShadow: 'inset 0 0 0 1px #E1E8EE',
    },
  };

  const detailsRowStyle = {
    boxShadow: 'inset 0 1px 0 0 #E1E8EE',
    verticalAlign: 'middle',
  };

  const buttonStyle = {
    cursor: 'pointer',
    fontFamily: 'OpenSans-Regular',
  };

  const isSessionLive =
    dayjs().isBetween(session.date, dayjs(session.date).add(44, 'minute')) && session?.isLive;

  const isSessionStarted = (startTime: Date | undefined | dayjs.Dayjs | string) => {
    const currentTime = dayjs();
    return currentTime.isAfter(startTime, 'minute');
  };

  const queryClient = useQueryClient();

  const sessionDeleteMutation = useMutation({
    mutationFn: () => deleteSession(session.id, userToken),
    onSettled: () => {
      // onSettled: (updatedSession) => {
      // queryClient.invalidateQueries({
      //   queryKey: ['sessions', dayjs(updatedSession.date).format('YYYY-MM-DD')],
      // });
      queryClient.invalidateQueries({
        queryKey: ['sessions'],
        exact: false, // Ensure all keys starting with 'sessions' are invalidated
      });
    },
  });

  const onSessionDelete = () => {
    sessionDeleteMutation.mutate();
  };

  const getSessionDateFormatted = () => {
    try {
      if (session.date) {
        const formattedDate = dayjs(session.date).tz(timeZone).format('HH:mm');
        return formattedDate;
      }
    } catch (error) {
      // console.error('Error occurred while getting offset:', error);
      // Return backup with indication:
      if (session.date) {
        return `${dayjs(new Date(session.date)).tz('EST').format('HH:mm')} EST time`;
      }
    }
  };

  return (
    <>
      {session && (
        <TableRow sx={rowStyle} className={`table-row ${isSessionLive ? 'live' : 'notLive'}`}>
          <TableCell component="th" scope="row">
            {session.date && <div>{getSessionDateFormatted()}</div>}
            {isSessionLive && (
              <div className="flex gap-5 align-center justify-start" style={{ paddingTop: '8px' }}>
                <PodcastsIcon />
                <span className="title">LIVE</span>
              </div>
            )}
          </TableCell>
          <TableCell>
            {session.topic && session.GSELevel && (
              <button
                type="button"
                style={buttonStyle}
                onClick={() => {
                  setTopicById(session.topic!.id);
                  navigate('/topicview');
                }}
              >
                <div className="topic title" style={{ textAlign: 'start' }}>
                  <span className="title">{session?.topic?.LangData?.title}</span>
                  <br />
                  <span style={{ fontSize: '12px', lineHeight: '130%' }} className="subtitle">
                    {session.GSELevel?.level}
                  </span>
                  <br />
                  <span className="subtitle"> {session.category.category}</span>
                </div>
              </button>
            )}
          </TableCell>
          <TableCell>
            <button
              type="button"
              style={buttonStyle}
              onClick={() => {
                if (session.tutor.id) {
                  setTutorById(session.tutor.id);
                  navigate('/tutorview');
                }
              }}
            >
              <div className="tutor flex column gap-5" style={{ textAlign: 'start' }}>
                <div className="flex gap-5">
                  <span>
                    <div>
                      {session.tutor.thumb ? (
                        <Avatar width={16} thumb={session.tutor.thumb} />
                      ) : (
                        <Avatar width={16} thumb={null} />
                      )}
                    </div>
                  </span>
                  {session.tutor.firstName ? (
                    <span className="title">
                      {session.tutor.firstName} {session.tutor.lastName}
                    </span>
                  ) : (
                    <span className="title">Pending tutor</span>
                  )}
                </div>
                {(session.vendor || session.tutor) && (
                  <span className="subtitle" style={{ lineHeight: '130%' }}>
                    {session.tutor.vendor ? session.tutor.vendor : session.vendor}
                  </span>
                )}
              </div>
            </button>
          </TableCell>
          <TableCell>
            <span className="title">
              {dayjs(session.date).add(44, 'minute').isBefore(dayjs())
                ? `${session.joinedUsers}/${session.maxParticipants} Joined`
                : `${session.enrollUsers}/${session.maxParticipants} Enrolled`}
            </span>
          </TableCell>
          <TableCell>
            <span className="title">{session.id}</span>
          </TableCell>
          <TableCell style={{ textAlign: 'end' }}>
            {!session.enrollUsers && session.date && !isSessionStarted(session.date) && (
              <button type="button" className="session-delete-icon" onClick={onSessionDelete}>
                <DeleteOutlinedIcon />
              </button>
            )}
          </TableCell>
          <TableCell>
            <IconButton
              className="expand-row"
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
      )}
      {/* collapsible: */}
      {session && (
        <TableRow
          sx={rowStyle}
          className={`table-row-expand ${isSessionLive ? 'live' : 'notLive'}`}
        >
          <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={7}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box
                sx={{
                  margin: 1,
                  display: 'flex',
                  justifyContent: 'space-between',
                  padding: '32px 0',
                }}
              >
                <Table size="small" aria-label="user details" sx={detailsTableStyle}>
                  <TableHead>
                    <TableRow>
                      <TableCell>User</TableCell>
                      <TableCell>ID</TableCell>
                      <TableCell>Duration</TableCell>
                      <TableCell>Join count</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody sx={{ verticalAlign: 'middle' }}>
                    {session!.users && session!.users?.length > 0 ? (
                      session.users.map((userRow) => (
                        <TableRow key={userRow.id} sx={detailsRowStyle}>
                          <TableCell component="th" scope="row">
                            {userRow.role}
                          </TableCell>
                          <TableCell>{userRow.id}</TableCell>
                          <TableCell>{formatSeconds(userRow.duration)}</TableCell>
                          <TableCell>{userRow.joinCount}</TableCell>
                        </TableRow>
                      ))
                    ) : (
                      <TableRow sx={detailsRowStyle}>
                        <TableCell>No session data</TableCell>
                      </TableRow>
                    )}
                  </TableBody>
                </Table>
                <div className="right-side-details" style={{ minWidth: '200px' }}>
                  {isSessionStarted(dayjs(session.date).add(44, 'minute')) && (
                    <DynamicButton
                      type="secondary"
                      size="small"
                      fullWidth
                      disable={!session.recordingLink}
                      onClick={() => handleCopyClick(session.recordingLink)}
                    >
                      {linkCopied ? 'Link copied!' : 'View recording'}
                    </DynamicButton>
                  )}

                  {!isSessionStarted(dayjs(session.date).add(44, 'minute')) && (
                    <button
                      type="button"
                      onClick={() => handleCopyClick(session.joinLink)}
                      className="join-url-btn"
                    >
                      <ContentCopyOutlinedIcon fontSize="small" />
                      <span>{linkCopied ? 'Link copied!' : 'Copy Join Link'}</span>
                    </button>
                  )}

                  <div className="flex space-between">
                    <span>Enrolled learners</span>

                    <span style={{ fontWeight: 600 }}>
                      {`${session.enrollUsers}/${session.maxParticipants}`}
                    </span>
                  </div>
                  <div className="flex space-between">
                    <span>Joined learners</span>

                    <span style={{ fontWeight: 600 }}>
                      {session?.users && session?.users?.length > 0
                        ? `${session.joinedUsers}/${session.maxParticipants}`
                        : '-'}
                    </span>
                  </div>
                </div>
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
