import { ReactNode } from 'react';
import AddRoundedIcon from '@mui/icons-material/AddRounded';
import { Button } from '@mui/material';
import { ReactComponent as FileUploadImg } from '../../assets/image/file_upload.svg';
import { ReactComponent as RefreshIcon } from '../../assets/image/refresh.svg';

interface DynamicButtonProps {
  size: 'large' | 'medium' | 'small';
  type: 'primary' | 'secondary' | 'tertiary' | 'action' | 'quaternary' | 'add-file';
  onClick?: () => void;
  children: ReactNode;
  addIcon?: boolean;
  nextIcon?: boolean;
  upIcon?: boolean;
  fileUploadIcon?: boolean;
  refreshIcon?: boolean;
  disable?: boolean;
  longPadding?: boolean;
  fullWidth?: boolean;
}

const DynamicButton = ({
  size,
  type,
  onClick,
  children,
  addIcon = false,
  nextIcon = false,
  upIcon = false,
  fileUploadIcon = false,
  refreshIcon = false,
  disable = false,
  longPadding = false,
  fullWidth = false,
}: DynamicButtonProps) => {
  const handleClick = () => {
    if (onClick) {
      onClick();
    }
  };

  return (
    <Button
      sx={{ textTransform: 'none' }}
      disabled={disable}
      className={`dynamic-button ${size} ${type} ${disable ? 'disable' : ''} ${
        longPadding ? 'long-padding' : ''
      } ${fullWidth ? 'fullWidth' : ''}`}
      onClick={handleClick}
      component="label"
    >
      {(addIcon || nextIcon || upIcon) && <AddRoundedIcon />}
      {fileUploadIcon && <FileUploadImg />}
      {refreshIcon && (
        <span className="refresh-icon">
          <RefreshIcon />
        </span>
      )}
      {children}
    </Button>
  );
};

export default DynamicButton;
