import { Dayjs } from 'dayjs';

export type Session = {
  id: number;
  groupId?: number | null;
  actualEndTime?: string | null;
  actualStartTime?: string | null;
  date: Date | string | undefined;
  enrollUsers: number;
  joinedUsers?: number;
  maxParticipants: number;
  enrollDate?: Date | undefined;
  // topic?: Topic;
  topic?: {
    id: number;
    contentSensitivity: boolean;
    title?: string;
    LangData: {
      title: string;
      description: string;
      mondlyTopic: MondlyTopic | null;
      lang: string;
    };
  };
  templateId?: number;
  // tutorId: number;
  tutor: {
    id: number;
    firstName: string;
    lastName: string;
    thumb: string;
    vendor: string;
    gender: string;
  };
  // vendorData: Vendor;
  vendor: string;
  category: Category;
  GSELevel?: GSELevel;
  location?: Location | undefined;
  timezone?: TimeZone;
  users?: { id: number; role: string; joinCount: number; duration: number }[];
  joinLink?: string;
  recordingLink?: string;
  isLive?: boolean;
};

// example session:
// {
//   "id": 2315,
//   "date": "2024-03-25T10:00:00.000Z",
//   "maxParticipants": 6,
//   "GSELevel": {
//       "id": 1,
//       "level": "A1 (22-29)",
//       "minGSE": 22,
//       "maxGSE": 29
//   },
//   "category": {
//       "id": 1,
//       "category": "General English"
//   },
//   "location": {
//       "id": 1,
//       "location": "US ($$$$$$)"
//   },
//   "vendorData": {
//       "vendor": "VIPKid",
//       "id": 1
//   },
//   "enrollUsers": 2,
//   "joinUsers": 1,
//   "tutor": {
//       "id": 59,
//       "firstName": "rt54",
//       "lastName": "t45t",
//       "thumb": "",
//       "vendor": "VIPKid",
//       "gender": "NOTSAY"
//   },
//   "topic": {
//       "id": 48,
//       "contentSensitivity": false,
//       "LangData": {
//           "title": "intermediate title",
//           "description": "intermediate Description",
//           "mondlyTopic": null,
//           "lang": "en_us"
//       }
//   }
// }

export type Topic = {
  id?: number;
  category: {
    id: number;
    category: string;
  };
  GSELevel: {
    minGSE: number;
    maxGSE: number;
  };
  languageData: LanguageData[];
  contentSensitivity: boolean;
  classMaterial?: ClassMaterial[];
};

export interface ClassMaterial {
  id: number;
  file: string;
  upload: boolean; // tutor-facing
  topicId: number;
}

export type LanguageData = {
  id?: number;
  language: string;
  title?: string;
  description?: string;
  mondlyTopic?: MondlyTopic | null;
};

export type User = {
  id: string;
  email?: string;
  name: string;
  state: number;
  roles: {
    premium: boolean;
    liveTutoring?: boolean;
    premiumLiveTutoring?: boolean;
  };
  b2b: {
    companyId: number | null;
    companyName: string | null;
    groupId: number | null;
    groupName: string | null;
  };
};

export type Tutor = {
  // tutor: any;
  id: number;
  firstName: string;
  lastName: string;
  email: string;
  thumb: string;
  gender: Genders;
  timeZone?: TimeZone;
  location?: Location;
  vendorData: {
    id: number;
    vendor: string;
  };
  vendorId: number;
  GSELevel: GSELevel[];
  category: Category[];
  lastSessionDate?: Date;
};

export type SurveyQuestion = {
  type: string;
  feedback: number | 0;
  comment: string;
};

export type FilterQuery = {
  filterLevel: string;
  filterDate: string;
};

export type DailyNotification = {
  time: number;
  id: number;
};

export type NotifyMeNotification = {
  time: number;
  id: number;
  date: Date;
  tutor: string;
  title: string;
};

export type ItemIdWithExpiry = {
  id: number;
  expiry: number;
};

// example Tutor with availability:
export type TutorEdit = {
  first_name: 'John';
  last_name: 'Doe';
  email: 'john.doe@example.com';
  timezone: 'UTC';
  availability: {
    Monday: {
      '00:00': false;
      '01:00': false;
      '02:00': false;
      '03:00': false;
      '04:00': false;
      '05:00': false;
      '06:00': false;
      '07:00': false;
      '08:00': false;
      '09:00': true;
      '10:00': false;
      '11:00': true;
      '12:00': true;
      '13:00': false;
      '14:00': false;
      '15:00': true;
      '16:00': true;
      '17:00': true;
      '18:00': false;
      '19:00': true;
      '20:00': true;
      '21:00': false;
      '22:00': false;
      '23:00': false;
    };
    Tuesday: {
      '00:00': false;
      '01:00': false;
      '02:00': false;
      '03:00': false;
      '04:00': false;
      '05:00': false;
      '06:00': false;
      '07:00': false;
      '08:00': false;
      '09:00': true;
      '10:00': true;
      '11:00': false;
      '12:00': true;
      '13:00': true;
      '14:00': false;
      '15:00': false;
      '16:00': true;
      '17:00': true;
      '18:00': true;
      '19:00': true;
      '20:00': false;
      '21:00': true;
      '22:00': false;
      '23:00': true;
    };
    Wednesday: {
      '00:00': false;
      '01:00': false;
      '02:00': false;
      '03:00': false;
      '04:00': false;
      '05:00': false;
      '06:00': false;
      '07:00': false;
      '08:00': false;
      '09:00': false;
      '10:00': true;
      '11:00': true;
      '12:00': true;
      '13:00': true;
      '14:00': false;
      '15:00': false;
      '16:00': false;
      '17:00': true;
      '18:00': true;
      '19:00': false;
      '20:00': true;
      '21:00': true;
      '22:00': false;
      '23:00': true;
    };
    Thursday: {
      '00:00': false;
      '01:00': false;
      '02:00': false;
      '03:00': false;
      '04:00': false;
      '05:00': false;
      '06:00': false;
      '07:00': false;
      '08:00': false;
      '09:00': true;
      '10:00': true;
      '11:00': false;
      '12:00': true;
      '13:00': false;
      '14:00': true;
      '15:00': true;
      '16:00': false;
      '17:00': true;
      '18:00': false;
      '19:00': false;
      '20:00': true;
      '21:00': true;
      '22:00': false;
      '23:00': true;
    };
    Friday: {
      '00:00': false;
      '01:00': false;
      '02:00': false;
      '03:00': false;
      '04:00': false;
      '05:00': false;
      '06:00': false;
      '07:00': false;
      '08:00': false;
      '09:00': false;
      '10:00': true;
      '11:00': false;
      '12:00': true;
      '13:00': true;
      '14:00': false;
      '15:00': true;
      '16:00': false;
      '17:00': true;
      '18:00': true;
      '19:00': true;
      '20:00': true;
      '21:00': false;
      '22:00': false;
      '23:00': false;
    };
    Saturday: {
      '00:00': false;
      '01:00': false;
      '02:00': false;
      '03:00': false;
      '04:00': false;
      '05:00': false;
      '06:00': false;
      '07:00': false;
      '08:00': false;
      '09:00': false;
      '10:00': false;
      '11:00': false;
      '12:00': false;
      '13:00': false;
      '14:00': false;
      '15:00': false;
      '16:00': false;
      '17:00': false;
      '18:00': false;
      '19:00': false;
      '20:00': false;
      '21:00': false;
      '22:00': false;
      '23:00': false;
    };
    Sunday: {
      '00:00': false;
      '01:00': false;
      '02:00': false;
      '03:00': false;
      '04:00': false;
      '05:00': false;
      '06:00': false;
      '07:00': false;
      '08:00': false;
      '09:00': false;
      '10:00': false;
      '11:00': false;
      '12:00': false;
      '13:00': false;
      '14:00': false;
      '15:00': false;
      '16:00': false;
      '17:00': false;
      '18:00': false;
      '19:00': false;
      '20:00': false;
      '21:00': false;
      '22:00': false;
      '23:00': false;
    };
  };
};

export type TimeZone = {
  label: string;
  offset: string;
};

export enum Levels {
  Beginner = 'Beginner',
  Intermediate = 'Intermediate',
  Advanced = 'Advanced',
}

export enum Categories {
  GeneralEnglish = 'General English',
  Grammar = 'Grammar',
  WorkplaceEnglish = 'Workplace English',
  SpecificPurpose = 'Specific purpose',
}

export interface Location {
  id: number;
  code: string;
  location: string;
}

export interface GSELevel {
  id: number;
  code: string;
  level: string;
  minGSE: number;
  maxGSE: number;
}

export interface Category {
  id: number;
  // code: string;
  category: string;
}

export interface Vendor {
  id: number;
  vendor: string;
}

export type CountryType = {
  code: string;
  label: string;
  phone: string;
  suggested?: boolean;
};

export enum contentSensitivity {
  standard = `Standard`,
  Adapted = 'Adapted for regional sensitivity',
}

export type Language = {
  id: number;
  code: string;
  language: string;
};

export enum Genders {
  MALE = 'Male',
  FEMALE = 'Female',
  OTHER = 'Other',
  NOTSAY = 'I prefer not to say',
}

export interface MondlyTopic {
  id: number;
  mondlyTopic?: string;
  language?: string;
  mondlyCategory?: string;
}

// export interface Group {
//   id: number;
//   name: string;
// }

// export interface SubOrganization {
//   id: number;
//   name: string;
//   group: Group[];
// }

export interface License {
  id: number;
  groupId: number;
  licenseId: number;
  quantity: number;
  startDate: Date | string;
  endDate: Date | string;
}

export interface Organization {
  id: number;
  name: string;
  licenses: License[];
  margin?: number;
  exclusionDates?: ExclusionInterval[] | null;
  children: Children[];
}

export interface Children {
  id: number;
  name: string;
  rootId?: number;
  parentId?: number;
  organizationName?: string;
  companyId?: number | null;
  licenses?: License[];
  margin?: number;
  timezone?: string | null;
  contentSensitivity?: boolean;
  weeklyLimit?: number;
  duration?: number; // in minutes
  schedulingWindow?: SchedulingWindow[] | [];
  // licenses?: number;
  startDate?: Date | string;
  endDate?: Date | string;
  GSELevel?: GSELevel[] | null; // We add this
  GSELevels?: GSELevel[] | null; // We add this
  categories?: Category[];
  classSize?: number;
  locations?: Location[] | null; // We add this
  gender?: Genders | string;
  exclusionDates?: ExclusionInterval[] | null; // We add this
  maxNumberOfStudents?: number | null;
  lastSessionDate?: string;
  // deleted?: boolean;
  // createdAt?: string;
  // updatedAt?: string;
  children?: Children[];
}

export enum WeekDays {
  MO = 'Monday',
  TU = 'Tuesday',
  WE = 'Wednesday',
  TH = 'Thursday',
  FR = 'Friday',
  SA = 'Saturday',
  SU = 'Sunday',
}

export interface ExclusionInterval {
  startDate: string;
  endDate: string;
}
export interface SchedulingInterval {
  start: string;
  end: string;
}
export interface SchedulingWindow {
  // key: 'MO' | 'TU' | 'WE' | 'TH' | 'FR' | 'SA' | 'SU';
  key: string;
  intervals: SchedulingInterval[] | [];
}

export interface PrivateSessionData {
  id?: number | undefined;
  date: Date | null;
  groupId: number | null;
  topic?: Topic | null;
  maxParticipants: number | null;
  timezone: string;
  vendorData?: Vendor | null;
  tutor?: Tutor | null;
  category?:
    | {
        id: number;
        category: string;
      }
    | undefined
    | null;
  GSELevel?:
    | {
        id: number;
        level: string;
        minGSE: number;
        maxGSE: number;
      }
    | undefined
    | null;
  locations: Location[] | undefined;
  joinLink?: string;
}
export interface MultipleSessionsRequestData {
  groupId: number | null;
  timeFrame: TimeFrame;
  GSELevels: GSELevel[] | undefined | null;
  // timezone: string;
  // date: Date;
  // maxParticipants: number | null;
  // groupSize: number | null;
  // vendorData: Vendor[] | null;
  // categories: Category[] | undefined | null;
  // locations: Location[] | undefined | null;
}

export interface TimeFrame {
  startDate: string;
  endDate: string;
  offset: number;
}

export interface TimeFramePublic {
  startDate: string | null;
  endDate: string | null;
  offset: number;
}
export interface DateRange {
  start: Dayjs | null;
  end: Dayjs | null;
}

export type TemplateSession = {
  startTime: string;
  weekday: boolean;
  vendor: string;
  category: Category[];
  GSELevel: GSELevel[];
  tutorRate?: Location | undefined;
};

export type ParsedSession = {
  Time: string; // e.g., "7:00:00" or can be empty string
  Day: 'Weekday' | 'Weekend' | ''; // Either "Weekday", "Weekend", or empty string
  Categories: string; // Semicolon-separated categories like "General English; Grammar"
  'CEFR/GSE level': string; // Semicolon-separated levels like "A1; A2; A2+"
  'Regional tutor rate': string; // Format: "RATE $$ - REGION", or empty
  Vendor: string; // Vendor name like "VIPKid", or empty string
};
