// import { useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Tutor } from '../utils/types';
// import Avatar from '../components/common/Avatar';
import DynamicButton from '../components/common/DynamicButton';
import { useUserContext } from '../components/Providers/UserContext';
// import { useTutorContext } from '../providers/TutorContext';
import Loader from '../components/common/Loader';
import TutorsTable from '../components/Tutor/TutorsTable';

interface TutorsProps {
  tutorList: Tutor[];
  isLoading: boolean;
}

const Tutors = ({ tutorList, isLoading }: TutorsProps) => {
  // const tutorsData = useRef<Tutor[]>(
  //   tutorList.sort(
  //     (a, b) =>
  //       new Date(b.lastSessionDate ?? 0).getTime() - new Date(a.lastSessionDate ?? 0).getTime(),
  //   ),
  // );
  const { hasRole } = useUserContext();
  const navigate = useNavigate();
  // const { setTutor } = useTutorContext();
  // const [tutorsData]

  return (
    <section className="tutor-page-container flex justify-center">
      <div className="tutor-page-content">
        {hasRole('Admin') && (
          <div
            className="add-tutor-btn flex space-between align-center full"
            style={{
              width: '100%',
            }}
          >
            <p className="h5-headline">Tutors</p>
            <DynamicButton
              size="medium"
              type="primary"
              addIcon={true}
              onClick={() => navigate('/tutoradd')}
            >
              New tutor
            </DynamicButton>
          </div>
        )}

        {tutorList && tutorList.length > 0 && isLoading ? (
          <Loader />
        ) : (
          <div className="tutor-list">
            <TutorsTable tutorList={tutorList} />
          </div>
        )}
      </div>
    </section>
  );
};

export default Tutors;
