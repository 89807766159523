import { ReactComponent as SuccessIcon } from '../../assets/image/banner-success-24.svg';
import { ReactComponent as ErrorIcon } from '../../assets/image/banner-error-24.svg';

interface LanguageBadgeProps {
  languageName: string;
  success: boolean;
}

export const LanguageBadge = ({ languageName, success }: LanguageBadgeProps) => {
  return (
    <section className="language-badge-section flex gap-8 align-center">
      {success ? <SuccessIcon /> : <ErrorIcon />}
      <p className={`language-name ${success ? '' : 'error'}`}>{languageName}</p>
    </section>
  );
};
