import React, { useEffect, useState } from 'react';
import { Autocomplete, FormControl, Box, TextField } from '@mui/material';

import * as moment from 'moment-timezone';
import 'moment-timezone/builds/moment-timezone-with-data';
import { TimeZone } from '../../utils/types';

interface TimezonePickerProps {
  smallLabelMargin?: boolean;
  value?: TimeZone;
  changeTimeZone: (val: TimeZone) => void;
}

export const TimezonePicker = ({
  smallLabelMargin = false,
  value,
  changeTimeZone,
}: TimezonePickerProps) => {
  const [selectedTimezone, setSelectedTimezone] = useState<TimeZone>(
    value
      ? value
      : {
          label: 'US/Eastern',
          offset: '-05:00',
        },
  );
  const [timezones, setTimezones] = useState<TimeZone[] | null>([]);

  useEffect(() => {
    const allTimeZones: string[] = moment.tz.names();
    const timezoneFormatted: TimeZone[] = allTimeZones.map((timezone) => {
      return {
        label: `${timezone.replace(/_/g, ' ')}  UTC${moment.tz(timezone).format('Z')}`, // Replace underscores with spaces
        offset: moment.tz(timezone).format('Z'),
      };
    });
    setTimezones(timezoneFormatted);
    const foundTimeZone = timezoneFormatted.find((tz) => tz.label.includes('US/Eastern'));
    if (foundTimeZone) {
      setSelectedTimezone({
        label: foundTimeZone.label,
        offset: foundTimeZone.offset,
      });
    }
  }, []);

  const handleChange = (event: React.SyntheticEvent<Element, Event>, value: TimeZone | null) => {
    if (value) {
      setSelectedTimezone(value);
    }
  };

  useEffect(() => {
    // Notify parent component of timezone changes
    changeTimeZone(selectedTimezone);
  }, [selectedTimezone, changeTimeZone]);

  return (
    <section className="timezone-picker" style={{ width: '100%' }}>
      <Box>
        <FormControl fullWidth>
          <p className={`form-label ${smallLabelMargin ? 'margin-8' : ''}`}>Time zone</p>
          {timezones && (
            <Autocomplete
              value={timezones.find((tz) => tz.label === selectedTimezone.label) || null}
              onChange={(event, value) => handleChange(event, value)}
              options={timezones}
              getOptionLabel={(option) => option.label}
              renderInput={(params) => <TextField {...params} fullWidth />}
            />
          )}
        </FormControl>
      </Box>
    </section>
  );
};
