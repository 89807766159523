import { useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import dayjs from 'dayjs';
import TitleBar from '../common/TitleBar';
import BackButton from '../layout/BackButton';
import DynamicButton from '../common/DynamicButton';
import InfoBadge from '../common/InfoBadge';
import { usePrivateGroupsContext } from '../../providers/privateGroupsContext';
import CustomSelect from '../form/CustomSelect';
import TopicSelect from '../form/TopicSelect';
import TutorSelect from '../form/TutorSelect';
import SingleSelectBtns from '../form/SingleSelectBtns';
import { useUserContext } from '../Providers/UserContext';
import useFetchGSELevel from 'Query/useFetchGSELevel';
import useFetchCategory from 'Query/useFetchCategory';
import useFetchVendors from 'Query/useFetchVendors';
import { savePrivateSession } from '../../utils/Network';
import {
  Topic,
  Tutor,
  Location,
  PrivateSessionData,
  contentSensitivity,
  GSELevel,
  Category,
  Vendor,
  Children,
} from '../../utils/types';
import { isCategory, isVendor, getDayKeyAsDayNumber } from '../../utils/utilsts';
import { DateTimePicker } from '../form/DateTimePicker';

interface SessionAddProps {
  tutorList: Tutor[];
  topicList: Topic[];
  setIsScheduleTab: (val: boolean) => void;
  selectedOrg: Children | null;
}

const PrivateSessionAdd = ({
  tutorList,
  topicList,
  setIsScheduleTab,
  selectedOrg,
}: SessionAddProps) => {
  const { selectedGroup, allOrgs } = usePrivateGroupsContext();
  const navigate = useNavigate();
  const { userToken } = useUserContext();
  const { data: GSELevels } = useFetchGSELevel(userToken);
  const { data: Categories } = useFetchCategory(userToken);
  const { data: Vendors } = useFetchVendors(userToken);

  const [selectedGSELevel, setSelectedGSELevel] = useState<GSELevel | null | undefined>(null);
  const [selectedCategory, setSelectedCategory] = useState<Category | null>(null);
  const [selectedLocations, setSelectedLocations] = useState<Location[] | undefined>(
    selectedGroup?.locations || undefined,
  );
  const [selectedVendor, setSelectedVendor] = useState<Vendor | null>(null);

  const getTopicListByPrivateGroupPreferences = () => {
    let filteredTopicList = structuredClone(topicList);
    // filter topics by group contentSensitivity, by group categories and by group GSELevels:
    if (selectedGroup) {
      filteredTopicList = filteredTopicList
        .filter((topic) => topic.contentSensitivity === selectedGroup.contentSensitivity)
        .filter((topic) => selectedGroup.categories?.some((cat) => cat.id === topic.category.id))
        .filter((topic) =>
          selectedGroup.GSELevels?.some(
            (level) =>
              topic.GSELevel.maxGSE >= level.minGSE && topic.GSELevel.maxGSE <= level.maxGSE,
          ),
        );
    }
    return filteredTopicList;
  };

  const getTutorListByPrivateGroupPreferences = () => {
    let filteredTutorList = structuredClone(tutorList);

    // filter topics by group contentSensitivity, gender, by group categories and by group GSELevels:

    if (selectedGroup) {
      filteredTutorList = filteredTutorList
        .filter((tutor) =>
          tutor.category.some((cat) =>
            selectedGroup.categories?.some((selectedCat) => selectedCat.id === cat.id),
          ),
        )
        .filter((tutor) =>
          selectedGroup.categories?.some((cat) =>
            tutor.category.some((tutorCat) => cat.id === tutorCat.id),
          ),
        )
        .filter((tutor) =>
          selectedGroup.locations?.some((loc) => tutor.location && loc.id === tutor.location.id),
        )
        .filter((tutor) =>
          selectedGroup.GSELevels?.some((level) =>
            tutor.GSELevel.some((tutorLevel) => level.id === tutorLevel.id),
          ),
        )
        .filter((tutor) => tutor.gender === selectedGroup.gender);
    }
    return filteredTutorList;
  };

  const [topicListGroupFilter, setTopicListGroupFilter] = useState<Topic[]>(
    getTopicListByPrivateGroupPreferences(),
  ); // initial filter
  const [tutorListGroupFilter, setTutorListGroupFilter] = useState<Tutor[]>(
    getTutorListByPrivateGroupPreferences(),
  ); // initial filter

  useEffect(() => {
    // filter topics by private group preferences:
    setTopicListGroupFilter(getTopicListByPrivateGroupPreferences());
    setTutorListGroupFilter(getTutorListByPrivateGroupPreferences());

    // dependency: selectedGroup, to make sure re-evaluation if selectedGroup changes:
  }, [selectedGroup]);

  const [topicListFilter, setTopicListFilter] = useState(topicListGroupFilter);
  const [tutorListFilter, setTutorListFilter] = useState(tutorListGroupFilter);
  const currOrg = allOrgs?.find((org) => org.id === selectedOrg?.id);
  const licensesEndDate =
    currOrg?.licenses && currOrg.licenses.length > 0
      ? currOrg?.licenses[currOrg.licenses.length - 1].endDate
      : dayjs().format('YYYY-MM-DD');

  const [sessionData, setSessionData] = useState<PrivateSessionData>({
    groupId: selectedGroup?.id || null,
    timezone: selectedGroup?.timezone || 'UTC',
    date: null,
    maxParticipants: selectedGroup?.maxNumberOfStudents || null,
    topic: null,
    vendorData: selectedVendor,
    tutor: null,
    category: selectedCategory,
    GSELevel: selectedGSELevel,
    locations: selectedLocations,
  });

  // form validation error states:
  const [isShowTopicRequired, setIsShowTopicRequired] = useState<boolean>(false);
  const [isShowVendorRequired, setIsShowVendorRequired] = useState<boolean>(false);

  const verifyRequiredFields = (data: PrivateSessionData): boolean => {
    if (!data.date) {
      return false;
    }
    if (data.maxParticipants && typeof data.maxParticipants !== 'number') {
      return false;
    }
    if (!data.topic) {
      return false;
    }
    if (!data.GSELevel) {
      return false;
    }
    if ((data.category && !isCategory(data.category)) || !data.category) {
      return false;
    }
    // if (data.locations !== undefined && !isLocation(data.location)) {
    //   return false;
    // }
    if ((data.vendorData && !isVendor(data.vendorData)) || !data.vendorData) {
      return false;
    }
    return true; // All checks passed
  };

  const getFullGSEByMax = (max: number) => {
    return GSELevels?.find((sLevel) => {
      return max >= sLevel.minGSE && max <= sLevel.maxGSE;
    });
  };

  const [isCreateSessionDisabled, setIsCreateSessionDisabled] = useState<boolean>(
    !verifyRequiredFields(sessionData),
  );

  const handleTopicChange = (selectedTopic: Topic) => {
    if (isShowTopicRequired) {
      setIsShowTopicRequired(false);
    }
    setSelectedCategory(selectedTopic.category);
    if (!sessionData.GSELevel) {
      const GSELevelFromTopic = getFullGSEByMax(selectedTopic.GSELevel.maxGSE);
      let updatedTopicList: Topic[] | undefined = filterTopicByCategory(
        topicListGroupFilter,
        selectedTopic.category.id,
      );
      if (GSELevelFromTopic) {
        updatedTopicList = filterTopicByGSE(
          updatedTopicList,
          GSELevelFromTopic.minGSE,
          GSELevelFromTopic.maxGSE,
        );
      }
      setSelectedGSELevel(GSELevelFromTopic);
      if (updatedTopicList) {
        setTopicListFilter(updatedTopicList);
      }
      setSessionData((prevData) => ({
        ...prevData,
        topic: selectedTopic,
        GSELevel: GSELevelFromTopic,
        category: selectedTopic.category,
      }));
    } else {
      setSessionData((prevData) => ({
        ...prevData,
        topic: selectedTopic,
        category: selectedTopic.category,
      }));
    }
    setIsCreateSessionDisabled(!verifyRequiredFields(sessionData));
  };

  const handleTutorChange = (selectedTutor: Tutor | undefined | null) => {
    if (selectedTutor) {
      setSessionData((prevData) => ({
        ...prevData,
        tutor: selectedTutor,
        vendorData: selectedTutor.vendorData,
      }));
      setSelectedVendor(selectedTutor.vendorData);
      if (selectedTutor.location && selectedTutor.location.location === 'Other') {
        setSelectedLocations(undefined);
      } else if (selectedTutor && selectedTutor.location) {
        setSelectedLocations([selectedTutor.location]);
      } else setSelectedLocations(undefined);
      setIsCreateSessionDisabled(!verifyRequiredFields(sessionData));
    }
  };

  useMemo(() => {
    setSessionData((prevFormData) => ({
      ...prevFormData,
      category: selectedCategory,
      GSELevel: selectedGSELevel,
      vendorData: selectedVendor,
    }));
  }, [selectedGSELevel, selectedLocations, selectedCategory, selectedVendor]);

  const filterTopicByCategory = (topics: Topic[], categoryId: number) => {
    return topics.filter((topic) => topic.category.id === categoryId);
  };

  const filterTutorByCategory = (tutors: Tutor[], categoryId: number) => {
    return tutors?.filter((tutor) =>
      tutor.category.some((sCategory) => sCategory.id === categoryId),
    );
  };

  const filterTopicByGSE = (topics: Topic[] | undefined, minGSE: number, maxGSE: number) => {
    return topics?.filter((topic) => {
      for (let i = topic.GSELevel.minGSE; i <= topic.GSELevel.maxGSE; i++) {
        if (i >= minGSE && i <= maxGSE) {
          return true;
        }
      }
      return false;
    });
  };

  const filterTutorByGSE = (tutors: Tutor[], gseLevelId: number) => {
    return tutors.filter((tutor) => tutor.GSELevel.some((sLevel) => sLevel.id === gseLevelId));
  };

  const filterTutorByLocations = (tutors: Tutor[], locations: Location[]) => {
    return tutors.filter((sTutor) => {
      return locations.some((loc) => loc.id === sTutor.location?.id);
    });
  };

  // const filterTutorByGender = (tutors: Tutor[], tutorGender: string) => {
  //   return tutors.filter((sTutor) => sTutor.gender === tutorGender);
  // };

  const filterTutorByVendor = (tutors: Tutor[], vendorId: number) => {
    return tutors.filter((sTutor) => sTutor.vendorData.id === vendorId);
  };

  const handleCategoryChange = (name: string, category: string) => {
    const sCategory = Categories?.find((cat) => cat.category === category);
    if (sCategory) {
      setSelectedCategory(sCategory);

      let updatedTopicList: Topic[] | undefined = filterTopicByCategory(
        topicListGroupFilter,
        sCategory.id,
      );
      if (selectedGSELevel) {
        updatedTopicList = filterTopicByGSE(
          updatedTopicList,
          selectedGSELevel.minGSE,
          selectedGSELevel.maxGSE,
        );
      }
      if (updatedTopicList) {
        setTopicListFilter(updatedTopicList);
      }
      // reset topic
      setSessionData((prevData) => ({
        ...prevData,
        topic: null,
      }));

      checkTutorByCategory(sCategory.id);
    }
  };

  const handleGSELevelChange = (name: string, cLevel: string) => {
    const sGSELevel = GSELevels?.find((level) => level.level === cLevel);
    if (sGSELevel) {
      setSelectedGSELevel(sGSELevel);

      let updatedTopicList: Topic[] | undefined = filterTopicByGSE(
        topicListGroupFilter,
        sGSELevel.minGSE,
        sGSELevel.maxGSE,
      );
      if (selectedCategory && updatedTopicList) {
        updatedTopicList = filterTopicByCategory(updatedTopicList, selectedCategory.id);
      }
      if (updatedTopicList) {
        setTopicListFilter(updatedTopicList);
      }
      // reset topic
      setSessionData((prevData) => ({
        ...prevData,
        topic: null,
      }));

      checkTutorByGSE(sGSELevel.id);
    }
  };

  const checkTutorByGSE = (GSELevelId: number) => {
    if (!sessionData?.tutor) {
      return;
    } else if (GSELevelId) {
      if (!sessionData.tutor.GSELevel?.some((level) => level.id === GSELevelId)) {
        setSessionData((prevData) => ({
          ...prevData,
          tutor: null,
          vendorData: null,
        }));
      }
    }
  };

  const checkTutorByCategory = (categoryId: number) => {
    if (!sessionData?.tutor) {
      return;
    } else if (categoryId) {
      if (!sessionData.tutor.GSELevel?.some((category) => category.id === categoryId)) {
        setSessionData((prevData) => ({
          ...prevData,
          tutor: null,
          vendorData: null,
        }));
      }
    }
  };

  const checkTutorFilterChangeByLocation = (location?: string) => {
    if (!sessionData?.tutor) {
      return;
    } else if (location) {
      // case 2: either sessionData location or vendor does not match current tutor, reset tutor:
      if (
        sessionData.vendorData?.id !== sessionData.tutor.vendorData.id ||
        location !== sessionData.tutor.location?.location
      ) {
        setSessionData((prevData) => ({
          ...prevData,
          tutor: null,
          vendorData: null,
        }));
      }
    }
  };

  const checkTutorFilterChangeByVendor = (vendorId: number) => {
    if (!sessionData?.tutor) {
      return;
    } else if (sessionData.vendorData) {
      // case 2: either sessionData location or vendor does not match current tutor, reset tutor:
      if (vendorId !== sessionData.tutor.vendorData.id) {
        setSessionData((prevData) => ({
          ...prevData,
          tutor: null,
        }));
      }
    }
  };

  // const handleLocationChange = (selectedLocation: Location | null) => {
  //   if (selectedLocation?.location) {
  //     setSelectedLocation(selectedLocation);
  //     checkTutorFilterChangeByLocation(selectedLocation.location);
  //   } else {
  //     setSelectedLocation(undefined);
  //   }
  // };

  const handleVendorSelect = (name: string, vendor: string) => {
    const sVendor = Vendors?.find((ven) => ven.vendor === vendor);
    if (sVendor) {
      setSelectedVendor(sVendor);
      checkTutorFilterChangeByVendor(sVendor?.id);
    }
    checkTutorFilterChangeByLocation();
  };

  const queryClient = useQueryClient();

  const sessionMutation = useMutation({
    mutationFn: () => savePrivateSession(sessionData, userToken),
    onSuccess: (newSession) => {
      queryClient.setQueryData(
        ['sessions', dayjs(newSession.date).format('YYYY-MM-DD'), newSession.groupId],
        (oldSessions: PrivateSessionData[]) => [...oldSessions, newSession],
      );
    },
    onSettled: (updatedSession) => {
      queryClient.invalidateQueries({
        queryKey: [
          'sessions',
          dayjs(updatedSession.date).format('YYYY-MM-DD'),
          updatedSession.groupId,
        ],
      });
      queryClient.invalidateQueries({
        queryKey: ['privateGroups'],
      });
      queryClient.invalidateQueries({
        queryKey: ['privateGroupHasSessions', updatedSession.groupId],
      });
    },
  });

  const handleSubmit = () => {
    if (!sessionData.vendorData) {
      setIsShowVendorRequired(true);
    }
    if (!sessionData.topic) {
      setIsShowTopicRequired(true);
    } else {
      // handleSaveSession();
      sessionMutation.mutate();
      setIsScheduleTab(true);
      navigate(`/privatesessions/${selectedGroup?.id}`);
    }
  };

  useEffect(() => {
    setIsCreateSessionDisabled(!verifyRequiredFields(sessionData));
  }, [sessionData]);

  const filterTutorList = () => {
    let updatedTutorList = [...tutorListGroupFilter];
    if (sessionData.category?.id) {
      updatedTutorList = filterTutorByCategory(updatedTutorList, sessionData.category.id);
    }
    if (sessionData.GSELevel?.id) {
      updatedTutorList = filterTutorByGSE(updatedTutorList, sessionData.GSELevel.id);
    }
    if (sessionData.vendorData) {
      updatedTutorList = filterTutorByVendor(updatedTutorList, sessionData.vendorData.id);
    }
    if (sessionData.locations) {
      updatedTutorList = filterTutorByLocations(updatedTutorList, sessionData.locations);
    }
    // if (selectedGroup?.gender === 'MALE' || selectedGroup?.gender === 'FEMALE') {
    //   updatedTutorList = filterTutorByGender(updatedTutorList, selectedGroup.gender);
    // }
    setTutorListFilter(updatedTutorList);
  };

  useEffect(() => {
    // filter tutors when affecting fields change:
    filterTutorList();
  }, [sessionData?.GSELevel, sessionData.category, sessionData.vendorData, sessionData.locations]);

  const handleChangeCustom = (name: string, value: string) => {
    setSessionData((prevData) => ({ ...prevData, [name]: value }));
    setIsCreateSessionDisabled(!verifyRequiredFields(sessionData));
  };

  // go back to schedule page if changing selectedGroup:
  useEffect(() => {
    if (sessionData.groupId && selectedGroup && sessionData.groupId !== selectedGroup.id) {
      navigate(`/privatesessions/${selectedGroup?.id}`);
    }
  }, [selectedGroup?.id]);

  const handleBackBtn = () => {
    setIsScheduleTab(true);
  };

  const weekdaysNoInterval = selectedGroup?.schedulingWindow?.filter(
    (el) => el.intervals.length === 0,
  );

  const schedulingWindowsDisableDays = weekdaysNoInterval?.map((el) =>
    getDayKeyAsDayNumber(el.key),
  ) as number[] | undefined;

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <section
        className="session-edit-section"
        style={{ flex: '1', width: '890px', paddingTop: '36px', background: 'white' }}
      >
        <div className="session-edit-container">
          <BackButton
            onClick={handleBackBtn}
            navigatePath={`/privatesessions/${selectedGroup?.id}`}
          />
          <div className="session-add-title flex column gap-8" style={{ paddingTop: '32px' }}>
            {selectedGroup && (
              <p style={{ fontSize: '12px', color: '#505759', margin: '0' }}>
                {' '}
                {selectedGroup.name}
              </p>
            )}
            <h1 style={{ margin: '0', fontFamily: 'OpenSans-Regular', fontWeight: '400' }}>
              {' '}
              Add single session
            </h1>
          </div>
          <div className="session-add-data-container">
            {sessionData?.timezone && (
              <div className="session-add-info-container">
                <DateTimePicker
                  handleChangeCustom={handleChangeCustom}
                  name={'date'}
                  timezone={sessionData?.timezone}
                  licensesEndDate={
                    licensesEndDate ? dayjs(licensesEndDate).format('YYYY-MM-DD') : undefined
                  }
                  disablePast
                  exclusionDates={currOrg?.exclusionDates || []}
                  schedulingWindowsDisableDays={schedulingWindowsDisableDays}
                />
              </div>
            )}
            <div>
              <TitleBar
                title={'Learner proficiency and content type'}
                iconType={'teachingContent'}
              />
              <div className="session-add-data-content">
                {Categories && (
                  <div>
                    <SingleSelectBtns
                      onChange={handleCategoryChange}
                      value={sessionData.category?.category}
                      label={'Category'}
                      name={'category'}
                      selectOptions={Categories.map((category) => category.category)}
                      groupOptions={selectedGroup?.categories?.map((category) => category.category)}
                    />
                  </div>
                )}
                {GSELevels && (
                  <div>
                    <SingleSelectBtns
                      onChange={handleGSELevelChange}
                      value={sessionData.GSELevel?.level}
                      label={'CEFR/GSE level'}
                      name={'GSELevel'}
                      selectOptions={GSELevels.map((level) => level.level)}
                      groupOptions={selectedGroup?.GSELevels?.map((level) => level.level)}
                    />
                  </div>
                )}
                <div className="flex gap-24 align-center">
                  <p style={{ paddingInlineEnd: '24px' }}>Content sensitivity</p>
                  <InfoBadge small>
                    {selectedGroup?.contentSensitivity ? (
                      <ReportSharpIcon />
                    ) : (
                      <ReportOffOutlinedIcon />
                    )}

                    {selectedGroup?.contentSensitivity
                      ? contentSensitivity.Adapted
                      : contentSensitivity.standard}
                  </InfoBadge>
                </div>
                <div>
                  <TopicSelect
                    topicList={topicListFilter}
                    onTopicChange={handleTopicChange}
                    value={sessionData.topic}
                    language={'en_us'}
                    error={isShowTopicRequired}
                  />
                </div>
              </div>
            </div>
            <div className="session-add-info-container">
              <TitleBar title={'Tutor'} iconType={'person'} />
              {/* {Locations && Locations.length && selectedGroup && selectedGroup.locations && (
                <div>
                  <PriceLocationSelect
                    onChange={handleLocationChange}
                    sessionLocation={sessionData?.location}
                    withAny={selectedGroup.locations.length >= Locations.length}
                    name={'location'}
                    label={'Regional tutor rate'}
                    groupOptions={selectedGroup?.locations}
                  />
                </div>
              )} */}

              <div>
                {Vendors && (
                  <CustomSelect
                    onChange={handleVendorSelect}
                    value={sessionData.vendorData?.vendor}
                    name={'vendorData'}
                    selectOptions={Vendors.map((vendor) => vendor.vendor).sort((a, b) =>
                      a.localeCompare(b),
                    )}
                    label={'Vendor'}
                    error={isShowVendorRequired}
                  />
                )}
              </div>
              <div>
                <TutorSelect
                  tutorList={tutorListFilter}
                  optional
                  onTutorChange={handleTutorChange}
                  value={sessionData?.tutor}
                  disable={!sessionData.GSELevel || !sessionData.category}
                  disableMsg={'Please select Category and CEFR/GSE level first'}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="session-add-action-btn-container">
          <div className="topic-edit-action-btns">
            <div className="submit-btns">
              <DynamicButton
                size="large"
                type="primary"
                disable={isCreateSessionDisabled}
                onClick={() => handleSubmit()}
              >
                Publish session
              </DynamicButton>
              <DynamicButton
                size="large"
                type="tertiary"
                onClick={() => {
                  navigate('/publicsessions');
                }}
              >
                Cancel
              </DynamicButton>
            </div>
          </div>
        </div>
        {/* <pre>{JSON.stringify(sessionData, null, 2)}</pre> */}
      </section>
    </LocalizationProvider>
  );
};

export default PrivateSessionAdd;
