import { useMemo, useState } from 'react';
import dayjs from 'dayjs';
import { Tutor } from '../../utils/types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TutorRow from './TutorRow';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

interface TutorsTableProps {
  tutorList: Tutor[];
}

const TutorsTable = ({ tutorList }: TutorsTableProps) => {
  const [sortBy, setSortBy] = useState<number>(5);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Track ascending/descending order
  // 1: by name, 2: by regional rate, 3: by gender, 4: by vendor, 5: by last session date

  const tutorsTableList = useMemo(() => {
    const sorted = [...tutorList];

    switch (sortBy) {
      case 1: // Sort by name
        sorted.sort((a, b) => a.firstName.toLowerCase().localeCompare(b.firstName.toLowerCase()));
        break;

      case 2: // Sort by regional rate
        sorted.sort((a, b) =>
          a.location?.id && b.location?.id ? a.location.id - b.location.id : 0,
        );
        break;

      case 3: // Sort by gender
        sorted.sort(
          (a, b) => a.gender.localeCompare(b.gender), // Assuming `gender` is a string
        );
        break;

      case 4: // Sort by vendor
        sorted.sort(
          (a, b) =>
            a.vendorData.vendor.toLowerCase().localeCompare(b.vendorData.vendor.toLowerCase()), // Assuming `vendorName` exists
        );
        break;

      case 5: // Sort by last session date
      default:
        // sorted.sort(
        //   (a, b) =>
        //     new Date(b.lastSessionDate ?? 0).getTime() - new Date(a.lastSessionDate ?? 0).getTime(),
        // );
        sorted.sort((a, b) => {
          const dateA = dayjs(a.lastSessionDate);
          const dateB = dayjs(b.lastSessionDate);

          // Handle invalid/missing dates
          if (!dateA.isValid()) return 1; // Place invalid/missing dates at the end
          if (!dateB.isValid()) return -1; // Place invalid/missing dates at the end

          // Sort in descending order (most recent dates first)
          return dateB.valueOf() - dateA.valueOf();
        });
        break;
    }

    // Reverse the order if sortOrder is 'desc'
    if (sortOrder === 'desc') {
      sorted.reverse();
    }

    return sorted;
  }, [sortBy, sortOrder, tutorList]); // Recompute only when `sortBy`, `sortOrder`, or `tutorList` changes

  const handleSortChange = (newSortBy: number) => {
    if (newSortBy === sortBy) {
      // Toggle sort order if the same sort option is selected
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      // Change sort criteria and reset to ascending order
      setSortBy(newSortBy);
      setSortOrder('asc');
    }
  };

  const headerStyle = { fontWeight: '600', borderBottom: '1px solid #151515' };
  const tableStyle = {
    border: '0',
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
    borderRadius: '4px',

    '& .MuiTableBody-root': {
      borderRadius: '4px',

      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          border: '0',
        },
      },
    },
  };

  return (
    <section
      className="tutors-table-container"
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      {/* <pre>{JSON.stringify(sortBy, null, 2)}</pre> */}
      <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
        <Table sx={tableStyle} stickyHeader aria-label="sticky collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={headerStyle}>
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(1)}
                >
                  <p>Name</p>
                  <span className={`${sortBy === 1 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              <TableCell sx={headerStyle}>
                {' '}
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(2)}
                >
                  <p>Regional rate</p>{' '}
                  <span className={`${sortBy === 2 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              <TableCell sx={headerStyle}>
                {' '}
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(3)}
                >
                  <p>Gender</p>{' '}
                  <span className={`${sortBy === 3 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              <TableCell sx={headerStyle}>
                {' '}
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(4)}
                >
                  <p>Vendor</p>{' '}
                  <span className={`${sortBy === 4 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              {/* <TableCell sx={headerStyle}>Creation date</TableCell> */}
              <TableCell sx={headerStyle}>
                {' '}
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(5)}
                >
                  <p>Last session date</p>{' '}
                  <span className={`${sortBy === 5 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              <TableCell sx={headerStyle} />
              {/* <TableCell sx={headerStyle} /> */}
            </TableRow>
          </TableHead>

          {tutorsTableList && (
            <TableBody>
              {tutorsTableList.map((tutor) => (
                <TutorRow key={tutor.id} tutor={tutor} />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </section>
  );
};

export default TutorsTable;
