import { useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import DynamicButton from './DynamicButton';

// interface AddDropdownBtnProps {
//   id?: number | null;
//   groupId?: number | null;
// }

// const AddDropdownBtn = ({ id = null, groupId = null }: AddDropdownBtnProps) => {
const AddDropdownBtn = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropdownRef = useRef<HTMLDivElement>(null);

  const toggleDropdown = () => setIsOpen((prev) => !prev);

  const handleOutsideClick = (event: MouseEvent) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
      setIsOpen(false);
    }
  };

  const onNavigateSingleSession = () => {
    setIsOpen(false);
    navigate('/sessionadd');
  };

  const onNavigateMultipleSessions = () => {
    setIsOpen(false);
    navigate('/multiplesessionaddpublic');
  };

  useEffect(() => {
    if (isOpen) {
      document.addEventListener('mousedown', handleOutsideClick);
    } else {
      document.removeEventListener('mousedown', handleOutsideClick);
    }
    return () => document.removeEventListener('mousedown', handleOutsideClick);
  }, [isOpen]);

  return (
    <div style={{ position: 'relative' }}>
      <DynamicButton
        size="medium"
        type="primary"
        addIcon
        longPadding
        onClick={() => toggleDropdown()}
      >
        Add
      </DynamicButton>
      {isOpen && (
        <div
          ref={dropdownRef}
          className="private-groups-add-sessions flex column"
          style={{
            background: '#FEFEFE',
            boxShadow: '0px 2px 4px 0px rgba(0, 0, 0, 0.25)',
            marginTop: '8px',
            borderRadius: '4px',
            position: 'absolute',
            zIndex: '5',
            right: '0px',
            width: '230px',
          }}
        >
          <button
            type="button"
            className="popup-btn-upper"
            onClick={() => {
              onNavigateMultipleSessions();
            }}
          >
            Add multiple sessions
          </button>
          <button
            type="button"
            className="popup-btn-bottom"
            onClick={() => onNavigateSingleSession()}
          >
            Add single session
          </button>
        </div>
      )}
    </div>
  );
};

export default AddDropdownBtn;
