import { TemplateSession } from '../../utils/types';
import { ReactComponent as GrammarCategoryIcon } from '../../assets/image/grammar.svg';
import { ReactComponent as GeneralEnglishCategoryIcon } from '../../assets/image/general-english.svg';
import { ReactComponent as SpecificPurposeCategoryIcon } from '../../assets/image/specific-purpose.svg';
import { ReactComponent as WorkplaceEnglish } from '../../assets/image/workplace-english.svg';
interface SessionTemplateCardProps {
  session: TemplateSession;
}

const SessionTemplateCard = ({ session }: SessionTemplateCardProps) => {
  return (
    <div className="session-template-card">
      <div className="flex gap-8 align-center full" style={{ width: '100%' }}>
        <div className="categories flex gap-8 full">
          <div
            className={`category-icon ${
              session.category.some((cat) => cat.id === 1) ? '' : 'not-applicable'
            }`}
          >
            <GeneralEnglishCategoryIcon />
          </div>
          <div
            className={`category-icon ${
              session.category.some((cat) => cat.id === 2) ? '' : 'not-applicable'
            }`}
          >
            <GrammarCategoryIcon />
          </div>
          <div
            className={`category-icon ${
              session.category.some((cat) => cat.id === 3) ? '' : 'not-applicable'
            }`}
          >
            <WorkplaceEnglish />
          </div>
          <div
            className={`category-icon ${
              session.category.some((cat) => cat.id === 4) ? '' : 'not-applicable'
            }`}
          >
            <SpecificPurposeCategoryIcon />
          </div>
        </div>
        <p className="level">{`${session.GSELevel.map((level) => level.code).join(', ')}`}</p>
      </div>
      <p className="location">{`${session.tutorRate?.code} - ${session.tutorRate?.location}`}</p>
      <p className="vendor">{`${session.vendor}`}</p>
    </div>
  );
};

export default SessionTemplateCard;
