import { TextField } from '@mui/material';
import { useUserContext } from '../components/Providers/UserContext';
import {
  apiGetUserList,
  getPollsData,
  getTutorRep,
  getUserFeedbacksData,
  getVendorUpcomingClass,
} from '../utils/Network';
import { useState } from 'react';

const Analytics = () => {
  const [startDate, setStartDate] = useState(new Date());

  function downloadTextAsFile(blob: Blob, filename: string) {
    const a = document.createElement('a');
    a.href = URL.createObjectURL(blob);
    a.download = filename;

    // Append the anchor element to the body
    document.body.appendChild(a);

    // Programmatically trigger a click event on the anchor element
    a.click();

    // Remove the temporary anchor element from the document
    document.body.removeChild(a);
  }

  const { userToken } = useUserContext();

  const getUserList = async (fromDate: Date) => {
    const from = fromDate.toISOString().split('T')[0];
    const userList = await apiGetUserList(from, userToken);
    downloadTextAsFile(userList, `${from}.csv`);
  };

  const getUserPolls = async (fromDate: Date) => {
    const from = fromDate.toISOString().split('T')[0];
    const vipUpcoming = await getPollsData(from, userToken);
    downloadTextAsFile(vipUpcoming, `${from}.csv`);
  };

  const getUserFeedbacks = async (fromDate: Date) => {
    const from = fromDate.toISOString().split('T')[0];
    const userFeedbacks = await getUserFeedbacksData(from, userToken);
    downloadTextAsFile(userFeedbacks, `feedbacks-${from}.csv`);
  };

  const getUpcomingClass = async (fromDate: Date, vendor: string) => {
    const from = fromDate.toISOString().split('T')[0];
    const upcomingClass = await getVendorUpcomingClass(from, vendor, userToken);
    downloadTextAsFile(upcomingClass, `upcoming-${from}.csv`);
  };

  const getTutorReport = async (fromDate: Date, vendor: string) => {
    const from = fromDate.toISOString().split('T')[0];
    const upcomingClass = await getTutorRep(from, vendor, userToken);
    downloadTextAsFile(upcomingClass, `tutorReport-${from}.csv`);
  };

  return (
    <>
      <div style={{ justifyContent: 'center', margin: '50px 150px' }}>
        <div style={{ marginBottom: '30px' }}>
          <TextField
            id="date"
            label="Start Date"
            type="date"
            defaultValue={startDate && startDate.toISOString().split('T')[0]}
            onChange={(e) => {
              setStartDate(new Date(e.target.value));
            }}
            InputLabelProps={{
              shrink: true,
            }}
          />
        </div>

        <div style={{ display: 'flex', gap: '40px', flexDirection: 'column' }}>
          <div
            onClick={() => getUserList(startDate)}
            style={{
              width: 150,
              height: 50,
              borderRadius: 12,
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexFlow: 'wrap',
              backgroundColor: '#a9dbd2',
              cursor: 'pointer',
            }}
          >
            Get User List
          </div>
          <div
            onClick={() => getUserPolls(startDate)}
            style={{
              width: 150,
              height: 50,
              borderRadius: 12,
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexFlow: 'wrap',
              backgroundColor: '#F44336',
              cursor: 'pointer',
            }}
          >
            User Polls
          </div>
          <div
            onClick={() => getUserFeedbacks(startDate)}
            style={{
              width: 150,
              height: 50,
              borderRadius: 12,
              padding: 10,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              flexFlow: 'wrap',
              backgroundColor: '#d7b98f',
              cursor: 'pointer',
            }}
          >
            User Feedbacks
          </div>
          <div
            style={{ display: 'flex', alignItems: 'center', justifyContent: 'start', gap: '30px' }}
          >
            <input
              style={{
                width: 200,
                height: 50,
                padding: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexFlow: 'wrap',
                backgroundColor: 'transparent',
                cursor: 'pointer',
                border: 'none',
                borderBottom: '1px solid #000',
              }}
              type="text"
              placeholder="Vendor Name"
              id="vendor"
            />
            <div
              onClick={() => {
                const vendor = (document.getElementById('vendor') as HTMLInputElement).value;
                getUpcomingClass(startDate, vendor);
              }}
              style={{
                width: 200,
                height: 50,
                borderRadius: 12,
                padding: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexFlow: 'wrap',
                backgroundColor: '#96d78f',
                cursor: 'pointer',
              }}
            >
              Get Upcoming Class
            </div>
            <div
              onClick={() => {
                const vendor = (document.getElementById('vendor') as HTMLInputElement).value;
                getTutorReport(startDate, vendor);
              }}
              style={{
                width: 200,
                height: 50,
                borderRadius: 12,
                padding: 10,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                flexFlow: 'wrap',
                backgroundColor: '#d38fd7',
                cursor: 'pointer',
              }}
            >
              Get Tutor Report
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default Analytics;
