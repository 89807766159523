/* eslint-disable @typescript-eslint/no-explicit-any */
import { useNavigate } from 'react-router-dom';
import TitleBar from '../common/TitleBar';
import Avatar from '../common/Avatar';
import DynamicButton from '../common/DynamicButton';
import blankAvatarImage from '../../assets/image/blank-avatar-24.png';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import 'react-week-calendar/dist/style.css';
import { Box } from '@mui/material';
import BackButton from '../layout/BackButton';
import InfoBadge from '../common/InfoBadge';
import { useTutorContext } from '../../providers/TutorContext';
import { DeleteTutor } from 'Query/useTutor';
import { useUserContext } from '../../components/Providers/UserContext';
import { Genders } from '../../utils/types';

const TutorAdd = () => {
  const navigate = useNavigate();
  const { tutor, setTutor } = useTutorContext();
  const { userToken } = useUserContext();

  const tutorMutation = DeleteTutor(tutor?.id, userToken);

  const tutorData = {
    id: tutor?.id,
    firstName: tutor?.firstName || '',
    lastName: tutor?.lastName || '',
    thumb: tutor?.thumb || '',
    gender: tutor?.gender || Genders.NOTSAY,
    timeZone: tutor?.timeZone || '',
    vendorData: tutor?.vendorData,
    vendorId: tutor?.vendorId,
    email: tutor?.email,
    // levels: [Levels.Beginner],
    GSELevel: tutor?.GSELevel || [],
    category: tutor?.category || [],
    location: tutor?.location,
  };

  const handleBackButtonClick = () => {
    setTutor(null);
  };

  const handleTutorDelete = (tutorId: number | undefined) => {
    tutorMutation.mutate(tutorId);
    navigate('/tutor');
  };

  function getGenderText(gender: string | undefined): string {
    switch (gender) {
      case 'MALE':
        return Genders.MALE;
      case 'FEMALE':
        return Genders.FEMALE;
      case 'OTHER':
        return Genders.OTHER;
      case 'NOTSAY':
        return Genders.NOTSAY;
      default:
        return 'Unknown'; // Fallback for unexpected values
    }
  }

  return (
    <section className="tutor-view-section">
      <div className="tutor-view-container">
        <BackButton navigatePath={'/tutor'} onClick={handleBackButtonClick} />
        <div className="actions-bar flex space-between align-center">
          <div className="tutor-name">
            {tutor && (
              <h1>
                {tutor.firstName} {tutor.lastName}
              </h1>
            )}
          </div>
          <div className="flex row gap-8">
            <DynamicButton
              type="primary"
              size="medium"
              longPadding
              onClick={() => {
                navigate('/tutoradd');
              }}
            >
              <EditOutlinedIcon />
              Edit profile
            </DynamicButton>
            <DynamicButton
              disable={true}
              size="medium"
              type="tertiary"
              longPadding
              onClick={() => handleTutorDelete(tutor?.id)}
            >
              <DeleteOutlinedIcon />
              Delete
            </DynamicButton>
          </div>
        </div>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: '40px',
          }}
        >
          <div className="general-title">
            <TitleBar title={'General'} iconType={'person'} />
            <div className="general-details">
              <div className="details-list flex column gap-16">
                <div className="flex">
                  <div className="info-title">Profile photo</div>
                  <div className="detail-content">
                    <Avatar width={72} thumb={tutor?.thumb || blankAvatarImage} />
                  </div>
                </div>
                <div className="flex">
                  <div className="info-title">Full name</div>
                  <div className="detail-content">
                    {tutorData.firstName} {tutorData.lastName}
                  </div>
                </div>
                <div className="flex">
                  <div className="info-title">Gender</div>
                  <div className="detail-content" style={{ textTransform: 'capitalize' }}>
                    {getGenderText(tutor?.gender)}
                  </div>
                </div>
                <div className="flex">
                  <div className="info-title">Time zone</div>
                  <div className="detail-content">
                    {tutor?.timeZone && (
                      <p className="margin-0 detail-content"> {tutor.timeZone.label}</p>
                    )}
                  </div>
                </div>
                <div className="flex">
                  <div className="info-title">Location</div>
                  <div className="detail-content">
                    {tutorData.location &&
                      `${tutor?.location?.code?.length ? tutor?.location.code + ' - ' : ''}${
                        tutor?.location?.location
                      }`}
                  </div>
                </div>
                <div className="flex">
                  <div className="info-title">Vendor</div>
                  <div className="detail-content"> {tutorData.vendorData?.vendor}</div>
                </div>
                <div className="flex">
                  <div className="info-title">Vendor ID</div>
                  <div className="detail-content"> {tutorData.vendorId}</div>
                </div>
              </div>
            </div>
          </div>

          <div className="contact-details">
            <TitleBar title={'Contact details'} iconType={'contact'} />
            <div className="contact-details">
              <div className="details-list flex">
                <div className="info-title">Email</div>
                <div className="detail-content"> {tutorData.email}</div>
              </div>
            </div>
          </div>
          <div className="teaching-content">
            <TitleBar title={'Teaching content'} iconType={'teachingContent'} />
            <div className="details-list flex column gap-16">
              <div className="flex">
                <div className="info-title">Levels</div>
                <div className="detail-content flex gap-8">
                  {tutorData.GSELevel?.map((level, index) => (
                    <InfoBadge small key={index}>
                      {level.level}
                    </InfoBadge>
                  ))}
                </div>
              </div>
              <div className="flex">
                <div className="info-title">Categories</div>
                <div className="detail-content flex gap-8">
                  {tutorData.category?.map((category, index) => (
                    <InfoBadge
                      small
                      key={index}
                      globeIcon={category.category === 'General English'}
                      grammarIcon={category.category === 'Grammar'}
                      briefcaseIcon={category.category === 'Workplace English'}
                      pinDropIcon={category.category === 'Specific purpose'}
                    >
                      {category.category}
                    </InfoBadge>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </Box>
      </div>
    </section>
  );
};

export default TutorAdd;
