import { useNavigate } from 'react-router-dom';
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { useUserContext } from '../Providers/UserContext';
import { useTopicContext } from '../../providers/TopicContext';
import { LanguageBadge } from './LanguageBadge';
import { orderedLanguages } from '../../utils/utilsts';
import { Topic } from '../../utils/types';
import DynamicButton from '../common/DynamicButton';

interface TopicLanguagesModalProps {
  topic: Topic;
  setIsTopicLanguagesModalOpen: (val: boolean) => void;
}

export const TopicLanguagesModal = ({
  topic,
  setIsTopicLanguagesModalOpen,
}: TopicLanguagesModalProps) => {
  const { hasRole } = useUserContext();
  const { setTopic } = useTopicContext();
  const navigate = useNavigate();
  const checkIsLanguageTranslation = (langCode: string) => {
    return topic.languageData.some((el) => el.language === langCode);
  };

  return (
    <section
      key={topic.id}
      className="topic-languages-modal-section flex column gap-8 align-center"
    >
      <div className="upper">
        <CloseOutlinedIcon
          onClick={() => setIsTopicLanguagesModalOpen(false)}
          style={{ cursor: 'pointer' }}
        />
      </div>
      <p className="h6-headline" style={{ margin: '0' }}>
        Translations
      </p>
      <p className="subtitle">
        {topic.languageData.find((el) => el.language === 'en_us')?.title ||
          topic.languageData[0].title}
      </p>
      <div className="flex gap-24" style={{ padding: '0 96px 32px' }}>
        <div className="languages">
          {orderedLanguages.slice(0, 17).map((lang) => (
            <LanguageBadge
              key={lang.code}
              languageName={lang.language}
              success={checkIsLanguageTranslation(lang.code)}
            />
          ))}
        </div>
        <div className="languages">
          {orderedLanguages.slice(17).map((lang) => (
            <LanguageBadge
              key={lang.code}
              languageName={lang.language}
              success={checkIsLanguageTranslation(lang.code)}
            />
          ))}
        </div>
      </div>
      <div style={{ paddingBottom: '38px' }}>
        <DynamicButton
          type="tertiary"
          size="large"
          onClick={() => {
            if (hasRole('Admin')) {
              setTopic(topic);
              navigate('/topicview');
            }
          }}
        >
          View topic
        </DynamicButton>
      </div>
    </section>
  );
};
