import { useNavigate } from 'react-router-dom';

import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
import { Tutor, Genders } from '../../utils/types';
import dayjs from 'dayjs';
import { useTutorContext } from '../../providers/TutorContext';
import Avatar from '../common/Avatar';
import blankAvatarImage from '../../assets/image/blank-avatar-24.png';

interface TutorRowProps {
  tutor: Tutor;
}

export default function TutorRow({ tutor }: TutorRowProps) {
  const navigate = useNavigate();
  const { setTutor } = useTutorContext();

  const rowStyle = {
    borderRadius: '4px',
    verticalAlign: 'center',

    boxShadow: 'inset 0 0 0 1px #E1E8EE',
    border: '1px solid var(--ui-elements-border-01, #DFE1E1)',
  };

  // example tutor:
  //   {
  //     "id": 10,
  //     "firstName": "Dalia",
  //     "lastName": "Blum",
  //     "timeZone": {
  //         "label": "US/Eastern  UTC-04:00",
  //         "offset": "-04:00"
  //     },
  //     "gender": "FEMALE",
  //     "location": {
  //         "id": 8,
  //         "location": "Southeast Asia",
  //         "code": "LOW $"
  //     },
  //     "vendorData": {
  //         "id": 1,
  //         "vendor": "VIPKid"
  //     },
  //     "vendorId": 22222,
  //     "email": "a@a.com",
  //     "GSELevel": [
  //         {
  //             "id": 1,
  //             "level": "A1 (10-29)",
  //             "minGSE": 10,
  //             "maxGSE": 29,
  //             "code": "A1"
  //         },
  //         {
  //             "id": 2,
  //             "level": "A2 (30-35)",
  //             "minGSE": 30,
  //             "maxGSE": 35,
  //             "code": "A2"
  //         },
  //         {
  //             "id": 3,
  //             "level": "A2+ (36-42)",
  //             "minGSE": 36,
  //             "maxGSE": 42,
  //             "code": "A2+"
  //         },
  //         {
  //             "id": 4,
  //             "level": "B1 (43-50)",
  //             "minGSE": 43,
  //             "maxGSE": 50,
  //             "code": "B1"
  //         },
  //         {
  //             "id": 5,
  //             "level": "B1+ (51-58)",
  //             "minGSE": 51,
  //             "maxGSE": 58,
  //             "code": "B1+"
  //         },
  //         {
  //             "id": 6,
  //             "level": "B2 (59-66)",
  //             "minGSE": 59,
  //             "maxGSE": 66,
  //             "code": "B2"
  //         },
  //         {
  //             "id": 7,
  //             "level": "B2+ (67-75)",
  //             "minGSE": 67,
  //             "maxGSE": 75,
  //             "code": "B2+"
  //         },
  //         {
  //             "id": 8,
  //             "level": "C1 (76-84)",
  //             "minGSE": 76,
  //             "maxGSE": 84,
  //             "code": "C1"
  //         },
  //         {
  //             "id": 9,
  //             "level": "C2 (85-90)",
  //             "minGSE": 85,
  //             "maxGSE": 90,
  //             "code": "C2"
  //         }
  //     ],
  //     "category": [
  //         {
  //             "id": 1,
  //             "category": "General English"
  //         },
  //         {
  //             "id": 2,
  //             "category": "Grammar"
  //         },
  //         {
  //             "id": 3,
  //             "category": "Workplace English"
  //         },
  //         {
  //             "id": 4,
  //             "category": "Specific purpose"
  //         }
  //     ],
  //     "thumb": "https://d13hgauxz9vfrb.cloudfront.net/courses/tutoring/thumbnails/deac67d6-882d-4456-b5e0-163c8fe958fd",
  //     "lastSessionDate": "2024-11-25T09:00:00.000Z"
  // },

  function getGenderText(gender: string | undefined): string {
    switch (gender) {
      case 'MALE':
        return Genders.MALE;
      case 'FEMALE':
        return Genders.FEMALE;
      case 'OTHER':
        return Genders.OTHER;
      case 'NOTSAY':
        return Genders.NOTSAY;
      default:
        return 'Unknown'; // Fallback for unexpected values
    }
  }

  return (
    <>
      {tutor && (
        <TableRow
          sx={rowStyle}
          className="tutor-row"
          onClick={() => {
            setTutor(tutor);
            navigate('/tutorview');
          }}
        >
          {/* Name */}
          <TableCell component="th" scope="row">
            <div className="flex gap-8 align-center">
              <Avatar width={24} thumb={tutor?.thumb || blankAvatarImage} />
              <p>{`${tutor.firstName} ${tutor.lastName}`}</p>
            </div>
          </TableCell>
          {/* Regional rate */}
          <TableCell>
            <p>{`${tutor.location?.code} - ${tutor.location?.location}`}</p>
          </TableCell>
          {/* Gender */}
          <TableCell>
            <p> {getGenderText(tutor?.gender)}</p>
          </TableCell>
          {/* Vendor */}
          <TableCell>
            <p> {tutor.vendorData.vendor}</p>
          </TableCell>
          {/* Last session date */}
          <TableCell>
            <p>{dayjs(tutor.lastSessionDate).format('YYYY-MM-DD')}</p>
          </TableCell>
          {/* Link caret btn */}
          <TableCell>
            <div className="caret-nav-btn">
              <ArrowForwardIosOutlinedIcon fontSize="small" />
            </div>
          </TableCell>
        </TableRow>
      )}
    </>
  );
}
