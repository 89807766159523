import dayjs from 'dayjs';
import { TemplateSession } from '../../utils/types';
import SessionTemplateCard from './SessionTemplateCard';

const hours = [
  '00:00',
  '01:00',
  '02:00',
  '03:00',
  '04:00',
  '05:00',
  '06:00',
  '07:00',
  '08:00',
  '09:00',
  '10:00',
  '11:00',
  '12:00',
  '13:00',
  '14:00',
  '15:00',
  '16:00',
  '17:00',
  '18:00',
  '19:00',
  '20:00',
  '21:00',
  '22:00',
  '23:00',
];

interface MultiplePublicSessionsPreviewProps {
  uploadedSessions: TemplateSession[];
}

const MultiplePublicSessionsPreview = ({
  uploadedSessions,
}: MultiplePublicSessionsPreviewProps) => {
  // Split sessions into weekday and weekend
  const weekdaySessions = uploadedSessions?.filter((session) => session.weekday);
  const weekendSessions = uploadedSessions?.filter((session) => !session.weekday);

  // Function to get sessions for a specific hour
  const getSessionsForTime = (hour: string, isWeekday: boolean) => {
    const filtered = (isWeekday ? weekdaySessions : weekendSessions).filter(
      (session) => dayjs(session.startTime, 'HH:mm:ss').format('HH:mm') === hour,
    );

    return filtered;
  };

  const getIsRowHasSession = (hour: string) => {
    const filtered = uploadedSessions.filter(
      (session) => dayjs(session.startTime, 'HH:mm:ss').format('HH:mm') === hour,
    );
    return filtered;
  };

  return (
    <section className="multiple-public-sessions-preview-section flex column full gap-48">
      <div className="header">
        <div className="flex full align-center">
          Weekday &nbsp;<span style={{ fontFamily: 'OpenSans-Regular' }}>(Monday - Friday)</span>
        </div>
        <div className="flex full align-center">
          Weekend &nbsp;<span style={{ fontFamily: 'OpenSans-Regular' }}>(Saturday & Sunday)</span>
        </div>
      </div>
      <div className="table">
        {hours.map((hour) => (
          <div
            key={hour}
            className={`hour-row ${getIsRowHasSession(hour).length > 0 ? 'has-sessions' : ''}`}
          >
            <div>{hour}</div>
            <div className="sessions weekday flex full" style={{ gap: '8px' }}>
              {getSessionsForTime(hour, true).map((session, index) => {
                return <SessionTemplateCard key={index} session={session} />;
              })}
            </div>
            <div className="sessions weekend flex full" style={{ gap: '8px' }}>
              {getSessionsForTime(hour, false).map((session, index) => {
                return <SessionTemplateCard key={index} session={session} />;
              })}
            </div>
          </div>
        ))}
      </div>
    </section>
  );
};

export default MultiplePublicSessionsPreview;
