import { useMemo, useState } from 'react';
import { Topic } from '../../utils/types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TopicRow from './TopicRow';
import FilterListOutlinedIcon from '@mui/icons-material/FilterListOutlined';

interface TopicsTableProps {
  topicList: Topic[];
}

const TopicsTable = ({ topicList }: TopicsTableProps) => {
  const [sortBy, setSortBy] = useState<number>(5);
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc'); // Track ascending/descending order

  const topicsTableList = useMemo(() => {
    const sorted = [...topicList];

    switch (sortBy) {
      case 1: // Sort by Title
        sorted.sort((a, b) => {
          // Extract the title for "en_us" language, or use an empty string if not found
          const titleA = a.languageData.find((item) => item.language === 'en_us')?.title || '';
          const titleB = b.languageData.find((item) => item.language === 'en_us')?.title || '';

          // Compare the titles in a case-insensitive manner
          return titleA.toLowerCase().localeCompare(titleB.toLowerCase());
        });
        break;

      case 2: // Sort by content sensitivity
        sorted.sort((a, b) => {
          return Number(a.contentSensitivity) - Number(b.contentSensitivity);
        });
        break;

      case 3: // Sort by category
        sorted.sort((a, b) => a.category.id - b.category.id);
        break;

      case 4: // Sort by Translations
        sorted.sort((a, b) => a.GSELevel.maxGSE - b.GSELevel.maxGSE);
        break;

      case 5: // Sort by Translations
        sorted.sort((a, b) => a.languageData.length - b.languageData.length);
        break;

      case 6: // Sort by class material length
      default:
        sorted.sort((a, b) => {
          const lengthA = a.classMaterial?.length || 0;
          const lengthB = b.classMaterial?.length || 0;
          return lengthA - lengthB;
        });
        break;
    }

    // Reverse the order if sortOrder is 'desc'
    if (sortOrder === 'desc') {
      sorted.reverse();
    }

    return sorted;
  }, [sortBy, sortOrder, topicList]); // Recompute only when `sortBy`, `sortOrder`, or `topicList` changes

  const handleSortChange = (newSortBy: number) => {
    if (newSortBy === sortBy) {
      // Toggle sort order if the same sort option is selected
      setSortOrder((prevOrder) => (prevOrder === 'asc' ? 'desc' : 'asc'));
    } else {
      // Change sort criteria and reset to ascending order
      setSortBy(newSortBy);
      setSortOrder('asc');
    }
  };

  const headerStyle = { fontWeight: '600', borderBottom: '1px solid #151515' };
  const tableStyle = {
    border: '0',
    borderCollapse: 'separate',
    borderSpacing: '0 4px',
    borderRadius: '4px',

    '& .MuiTableBody-root': {
      borderRadius: '4px',

      '& .MuiTableRow-root': {
        '& .MuiTableCell-root': {
          border: '0',
        },
      },
    },
  };

  return (
    <section
      className="tutors-table-container"
      style={{ display: 'flex', justifyContent: 'center' }}
    >
      {/* <pre>{JSON.stringify(sortBy, null, 2)}</pre> */}
      <TableContainer sx={{ boxShadow: 'none' }} component={Paper}>
        <Table sx={tableStyle} stickyHeader aria-label="sticky collapsible table">
          <TableHead>
            <TableRow>
              <TableCell sx={headerStyle}>
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(1)}
                >
                  <p>Title</p>
                  <span className={`${sortBy === 1 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              <TableCell sx={headerStyle}>
                {' '}
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(2)}
                >
                  <p>Content</p>{' '}
                  <span className={`${sortBy === 2 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              <TableCell sx={headerStyle}>
                {' '}
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(3)}
                >
                  <p>Categoty</p>{' '}
                  <span className={`${sortBy === 3 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              <TableCell sx={headerStyle}>
                {' '}
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(4)}
                >
                  <p>CEFR/GSE level</p>{' '}
                  <span className={`${sortBy === 4 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              {/* <TableCell sx={headerStyle}>Creation date</TableCell> */}
              <TableCell sx={headerStyle}>
                {' '}
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(5)}
                >
                  <p>Translations</p>{' '}
                  <span className={`${sortBy === 5 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              {/* <TableCell sx={headerStyle}>Creation date</TableCell> */}
              <TableCell sx={headerStyle}>
                {' '}
                <div
                  className="tutor-table-column-title flex gap-8 align-center"
                  onClick={() => handleSortChange(5)}
                >
                  <p>Files</p>{' '}
                  <span className={`${sortBy === 6 && sortOrder === 'asc' ? 'rotate' : ''}`}>
                    <FilterListOutlinedIcon />
                  </span>
                </div>
              </TableCell>
              <TableCell sx={headerStyle} />
              {/* <TableCell sx={headerStyle} /> */}
            </TableRow>
          </TableHead>

          {topicsTableList && (
            <TableBody>
              {topicsTableList.map((topic) => (
                <TopicRow key={topic.id} topic={topic} />
              ))}
            </TableBody>
          )}
        </Table>
      </TableContainer>
    </section>
  );
};

export default TopicsTable;
