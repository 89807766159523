/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  ReactNode,
  createContext,
  useContext,
  useMemo,
  useState,
  memo,
  useCallback,
  useEffect,
} from 'react';
import { LoginAPI, LoginUserContext } from '@yoda/components-core';

//type AdminRole = 'Admin' | 'Viewer' | 'Moderator';

type User = { username: string; name: string; token: string };

interface UserContextProps {
  user: any;
  init: boolean;
  azureAPI: any;
  hasRole: (role: string) => boolean;
  userToken: string;
  hasAnyRole: () => boolean;
  login: () => void;
  logout: () => void;
}

const emptyContext: UserContextProps = {
  user: null,
  init: false,
  azureAPI: new LoginAPI(),
  userToken: '',
  hasRole: () => false,
  hasAnyRole: () => false,
  login: () => {},
  logout: () => {},
};

const UserContext = createContext(emptyContext);

interface UserProviderProps {
  children: ReactNode;
}

// const UserContext: any = createContext({
//   user: null,
//   azureAPI: new LoginAPI(),
//   hasRole: () => false,
//   hasAnyRole: () => false,
//   login: () => {},
//   logout: () => {},
// });

export const UserProvider = memo(({ children }: UserProviderProps) => {
  const [user, setUser] = useState<any>(null);
  const [init, setInit] = useState(false);
  const [azureAPI] = useState(() => new LoginAPI());

  useEffect(() => {
    azureAPI.getLoggedInUser((user: any) => {
      setUser(user);
      setInit(true);
    });
  }, [azureAPI.getLoggedInUser]);

  const roles = useMemo(() => {
    return user?.idTokenClaims?.roles || [];
  }, [user?.idTokenClaims?.roles]);

  const userToken = useMemo(() => {
    if (user?.token) return user.token;
  }, [user?.token]);

  const getUserToken = useCallback(() => {
    return new Promise<string>((resolve, reject) => {
      new LoginAPI().getLoggedInUser((loggedInUser: User) => {
        if (loggedInUser) {
          setUser(loggedInUser);
        } else {
          reject({ message: 'user is not logged in...' });
        }
      });
    });
  }, []);

  useEffect(() => {
    const intevelId = setInterval(() => {
      getUserToken();
    }, 1000 * 60 * 15);
    return () => clearInterval(intevelId);
  }, []);

  const hasRole = useCallback(
    (role: string) => {
      // return true;
      // return role === 'Tutor'
      const queryParams = new URLSearchParams(window.location.search);
      const isTutor = queryParams.get('tutor') && queryParams.get('tutor') === 'true';
      if (roles.includes('Admin')) {
        if (isTutor) {
          return role === 'Tutor';
        }
      }
      return !!roles.includes(role);
    },
    [roles],
  );

  const value = useMemo(() => {
    const updatedUser = user
      ? {
          ...user,
          refreshTokenCallback: (_unusedUserArg: any, callback: any) => {
            // NOTE: `_unusedUserArg` has modified `refreshTokenCallback`, do not use it here
            user.refreshTokenCallback?.(user, (token: string) => {
              setUser({ ...user, token });
              callback(token);
            });
          },
        }
      : null;

    return {
      user: updatedUser,
      init,
      azureAPI,
      userToken,
      hasRole,
      hasAnyRole: () => !!roles.length,
      login: () => {
        azureAPI.login('loginPopup', setUser).catch(console.error);
      },
      logout: () => {
        azureAPI.logout().catch(console.error);
      },
    };
  }, [user, roles, hasRole, azureAPI, userToken, init]);

  return (
    <UserContext.Provider value={value}>
      <LoginUserContext.Provider value={{ user, setUser }}>{children}</LoginUserContext.Provider>
    </UserContext.Provider>
  );
});

// fix for eslint:
UserProvider.displayName = 'UserProvider';

export const useUserContext = () => useContext(UserContext);
