import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import TableCell from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import Dialog from '@mui/material/Dialog';
import ArrowForwardIosOutlinedIcon from '@mui/icons-material/ArrowForwardIosOutlined';
// import PublicOutlinedIcon from '@mui/icons-material/PublicOutlined';
// import SpellcheckOutlinedIcon from '@mui/icons-material/SpellcheckOutlined';
// import WorkOutlineOutlinedIcon from '@mui/icons-material/WorkOutlineOutlined';
// import PinDropOutlinedIcon from '@mui/icons-material/PinDropOutlined';
import ReportOffOutlinedIcon from '@mui/icons-material/ReportOffOutlined';
import ReportSharpIcon from '@mui/icons-material/ReportSharp';
import { Topic } from '../../utils/types';
import { useTopicContext } from '../../providers/TopicContext';
import { useUserContext } from '../Providers/UserContext';
import { gseRangeToCEFR } from '../../utils/utilsts';
import InfoBadge from '../common/InfoBadge';
import { TopicLanguagesModal } from './TopicLanguagesModal';

interface TutorRowProps {
  topic: Topic;
}

export default function TutorRow({ topic }: TutorRowProps) {
  const navigate = useNavigate();
  const { setTopic } = useTopicContext();
  const { hasRole } = useUserContext();
  const [isTopicLanguagesModalOpen, setIsTopicLanguagesModalOpen] = useState<boolean>(false);

  const rowStyle = {
    borderRadius: '4px',
    verticalAlign: 'center',

    boxShadow: 'inset 0 0 0 1px #E1E8EE',
    border: '1px solid var(--ui-elements-border-01, #DFE1E1)',
  };

  const getCEFRRangeFormatted = () => {
    const range = gseRangeToCEFR(topic.GSELevel.minGSE, topic.GSELevel.maxGSE);
    if (range && range.length > 1) {
      return (
        <span>
          {range[0]}&nbsp;-&nbsp;{range[range.length - 1]}{' '}
        </span>
      );
    } else {
      return <span>{range[0]}</span>;
    }
  };

  const handeOpenTopicLanguagesModal = (ev: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => {
    ev.stopPropagation();
    setIsTopicLanguagesModalOpen(true);
  };

  return (
    <>
      {topic && (
        <TableRow
          sx={rowStyle}
          className="tutor-row"
          onClick={() => {
            if (hasRole('Admin')) {
              setTopic(topic);
              navigate('/topicview');
            }
          }}
        >
          {/* Title */}
          <TableCell component="th" scope="row">
            <div className="flex gap-8 align-center">
              <p>{`${
                topic.languageData.find((item) => item.language === 'en_us')?.title || ''
              }`}</p>
            </div>
          </TableCell>
          {/* Content */}
          <TableCell>
            <p>{topic.contentSensitivity ? <ReportSharpIcon /> : <ReportOffOutlinedIcon />}</p>
          </TableCell>
          {/* Categoty */}
          <TableCell>
            {topic?.category.category && (
              <InfoBadge
                small
                globeIcon={topic?.category.category === 'General English'}
                grammarIcon={topic?.category.category === 'Grammar'}
                briefcaseIcon={topic?.category.category === 'Workplace English'}
                pinDropIcon={topic?.category.category === 'Specific purpose'}
              >
                {topic?.category.category}
              </InfoBadge>
            )}
          </TableCell>
          {/* CEFR/GSE level */}
          <TableCell>
            <p>
              {getCEFRRangeFormatted()}&nbsp; ({topic?.GSELevel.minGSE}&nbsp;-&nbsp;
              {topic?.GSELevel.maxGSE})
            </p>
          </TableCell>
          {/* Translations */}
          <TableCell>
            <p className="topic-row-lang" onClick={(ev) => handeOpenTopicLanguagesModal(ev)}>
              {topic.languageData.length}
            </p>
          </TableCell>
          {/* Class Material */}
          <TableCell>
            <p>{topic.classMaterial?.length || `N/A`}</p>
          </TableCell>

          {/* Link caret btn */}
          <TableCell>
            <div className="caret-nav-btn">
              <ArrowForwardIosOutlinedIcon fontSize="small" />
            </div>
          </TableCell>
        </TableRow>
      )}
      <Dialog
        open={isTopicLanguagesModalOpen}
        onClose={() => setIsTopicLanguagesModalOpen(false)}
        className="topic-languages-modal-wrapper"
        maxWidth={'xl'}
      >
        <TopicLanguagesModal
          topic={topic}
          setIsTopicLanguagesModalOpen={setIsTopicLanguagesModalOpen}
        />
      </Dialog>
    </>
  );
}
